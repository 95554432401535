var DescriptionDefinitions = {};
DescriptionDefinitions.ACTION = "action";
DescriptionDefinitions.ACTIONS = "actions";
DescriptionDefinitions.ACTION_ID = "id";
DescriptionDefinitions.ACTION_MODE = "mode";
DescriptionDefinitions.ACTION_SEQUENCE_ID = "sequenceId";
DescriptionDefinitions.ACTION_URL = "url";
DescriptionDefinitions.ANIMATION_DELAY_IN_MILLISECONDS = "delayInMilliseconds";
DescriptionDefinitions.ANIMATION_NAME = "name";
DescriptionDefinitions.ANIMATION_URL = "url";
DescriptionDefinitions.ASSET_IDS = "assetIds";
DescriptionDefinitions.BORDER_COLOR = "borderColor";
DescriptionDefinitions.BORDER_TYPE = "borderType";
DescriptionDefinitions.BORDER_WIDTH = "borderWidth";
DescriptionDefinitions.BOTTOM_RIGHT = "bottomRight";
DescriptionDefinitions.CALL_TO_ACTION_BACKGROUND_COLOR = "backgroundColor";
DescriptionDefinitions.CALL_TO_ACTION_SIZE = "size";
DescriptionDefinitions.COMPUTATION = "computation";
DescriptionDefinitions.CONDITIONS = "conditions";
DescriptionDefinitions.COORDINATES_X = "x";
DescriptionDefinitions.COORDINATES_Y = "y";
DescriptionDefinitions.COUNT = "count";
DescriptionDefinitions.CTA = "cta";
DescriptionDefinitions.DELAY_DURATION_IN_MILLISECONDS = "delayDurationInMilliseconds";
DescriptionDefinitions.DEPTH = "depth";
DescriptionDefinitions.DISPLAY_PERCENTAGE = "displayPercentage";
DescriptionDefinitions.DOWNLOAD_SIZE = "downloadSize";
DescriptionDefinitions.DURATION_IN_MILLISECONDS = "durationInMilliseconds";
DescriptionDefinitions.EXPERIENCE_DESCRIPTION_ANIMATIONS = "animations";
DescriptionDefinitions.EXPERIENCE_DESCRIPTION_COLOR = "color";
DescriptionDefinitions.EXPERIENCE_DESCRIPTION_METRICS = "metrics";
DescriptionDefinitions.EXPERIENCE_DESCRIPTION_TYPE = "type";
DescriptionDefinitions.EXTENDABLE = "extendable";
DescriptionDefinitions.EXTERNALS = "externals";
DescriptionDefinitions.EXTERNAL_ASSETS = "externalAssets";
DescriptionDefinitions.EXTERNAL_ASSET_ID = "id";
DescriptionDefinitions.EXTERNAL_ASSET_TYPE = "type";
DescriptionDefinitions.EXTERNAL_ASSET_URL = "url";
DescriptionDefinitions.FIRST_CLICK = "firstClick";
DescriptionDefinitions.FONT = "font";
DescriptionDefinitions.FONT_COLOR = "fontColor";
DescriptionDefinitions.FONT_SIZE = "fontSize";
DescriptionDefinitions.FONT_SIZE_COMPUTATION = "fontSizeComputation";
DescriptionDefinitions.FONT_URL = "fontUrl";
DescriptionDefinitions.FOREGROUND_COLOR = "foregroundColor";
DescriptionDefinitions.FRAME_HEIGHT = "frameHeight";
DescriptionDefinitions.FRAME_HEIGHT_COMPUTATION = "frameHeightComputation";
DescriptionDefinitions.FRAME_WIDTH = "frameWidth";
DescriptionDefinitions.FRAME_WIDTH_COMPUTATION = "frameWidthComputation";
DescriptionDefinitions.FREE_PERCENTAGE = "freePercentage";
DescriptionDefinitions.FROM = "from";
DescriptionDefinitions.GRAVITY_PERCENTAGE = "gravityPercentage";
DescriptionDefinitions.GYROSCOPIC = "gyroscopic";
DescriptionDefinitions.HEIGHT = "height";
DescriptionDefinitions.HEIGHT_COMPUTATION = "heightComputation";
DescriptionDefinitions.HOT_ZONE = "hotZone";
DescriptionDefinitions.HOT_ZONE_ANIMATION_DURATION_IN_MILLISECONDS = "animationDurationInMilliseconds";
DescriptionDefinitions.HOT_ZONE_IDLE_DURATION_IN_MILLISECONDS = "idleDurationInMilliseconds";
DescriptionDefinitions.HOT_ZONE_MODE = "mode";
DescriptionDefinitions.HOT_ZONE_PERCENTAGE = "percentage";
DescriptionDefinitions.HOT_ZONE_TYPE = "type";
DescriptionDefinitions.HOVER = "hover";
DescriptionDefinitions.HOVER_ANIMATION_DURATION_IN_MILLISECONDS = "animationDurationInMilliseconds";
DescriptionDefinitions.HOVER_COLOR = "color";
DescriptionDefinitions.HOVER_LOOP = "loop";
DescriptionDefinitions.HOVER_TRACK_ID = "trackId";
DescriptionDefinitions.ICON = "icon";
DescriptionDefinitions.ICON_COLOR = "iconColor";
DescriptionDefinitions.INPUT = "input";
DescriptionDefinitions.INPUT_TYPE = "type";
DescriptionDefinitions.ITEMS = "items";
DescriptionDefinitions.LAYERS = "layers";
DescriptionDefinitions.LAYER_COMMENT = "comment";
DescriptionDefinitions.LAYER_ENABLED = "enabled";
DescriptionDefinitions.LAYER_ID = "id";
DescriptionDefinitions.LAYER_MODE = "mode";
DescriptionDefinitions.LAYER_OPERATION = "operation";
DescriptionDefinitions.LENTICULAR_LOOP = "loop";
DescriptionDefinitions.MAGNET = "magnet";
DescriptionDefinitions.MAGNET_DURATION_IN_MILLISECONDS = "magnetDurationInMilliseconds";
DescriptionDefinitions.MASK = "mask";
DescriptionDefinitions.MODEL_VERSION = "modelVersion";
DescriptionDefinitions.MOTION_RANGE = "motionRange";
DescriptionDefinitions.NAVIGATION_ZONE_PERCENTAGE = "navigationZonePercentage";
DescriptionDefinitions.ORDER = "order";
DescriptionDefinitions.PARAMETERS = "parameters";
DescriptionDefinitions.PARTICLE_ANIMATIONS = "animations";
DescriptionDefinitions.PARTICLE_ANIMATION_INTERPOLATOR = "interpolator";
DescriptionDefinitions.PARTICLE_COMMENT = "comment";
DescriptionDefinitions.PARTICLE_ENABLED = "enabled";
DescriptionDefinitions.PARTICLE_ID = "id";
DescriptionDefinitions.PARTICLE_OPERATION = "operation";
DescriptionDefinitions.PARTICLE_STATE_COLOR = "color";
DescriptionDefinitions.PARTICLE_STATE_POINTER_PERCENTAGE = "percentage";
DescriptionDefinitions.PARTICLE_STATE_X = "x";
DescriptionDefinitions.PARTICLE_STATE_Y = "y";
DescriptionDefinitions.POIS = "pois";
DescriptionDefinitions.POST_PERCENTAGE = "postPercentage";
DescriptionDefinitions.PRE_PERCENTAGE = "prePercentage";
DescriptionDefinitions.PROCESS = "process";
DescriptionDefinitions.PROCESS_SCORES = "scores";
DescriptionDefinitions.PROGRAMS = "programs";
DescriptionDefinitions.PROGRAM_ANIMATION_DURATION_IN_MILLISECONDS = "animationDurationInMilliseconds";
DescriptionDefinitions.PROGRAM_DELAY_IN_MILLISECONDS = "delayInMilliseconds";
DescriptionDefinitions.PROGRAM_DISTANCE_PERCENTAGE = "distancePercentage";
DescriptionDefinitions.PROGRAM_LOOP = "loop";
DescriptionDefinitions.PROGRAM_MAGNET_IDLE_DURATION_IN_MILLISECONDS = "idleDurationInMilliseconds";
DescriptionDefinitions.PROGRAM_MAGNET_PERCENTAGE = "percentage";
DescriptionDefinitions.PROGRAM_PERCENTAGE = "percentage";
DescriptionDefinitions.PROGRAM_TRACK_ID = "trackId";
DescriptionDefinitions.PROGRAM_TRANSITION_DISTANCE_PERCENTAGE = "distancePercentage";
DescriptionDefinitions.PROGRAM_TRANSITION_INTERPOLATOR = "interpolator";
DescriptionDefinitions.PROGRAM_TRANSITION_TYPE = "type";
DescriptionDefinitions.RANGE = "range";
DescriptionDefinitions.RATIO = "ratio";
DescriptionDefinitions.REACH_PERCENTAGE = "reachPercentage";
DescriptionDefinitions.REVERSE = "reverse";
DescriptionDefinitions.RICH_TEXT_STYLE_ITEM_NAME = "name";
DescriptionDefinitions.ROTATION = "rotation";
DescriptionDefinitions.SCORE_ACTION_ID = "actionId";
DescriptionDefinitions.SCORE_ID = "id";
DescriptionDefinitions.SCORE_METRICS = "metrics";
DescriptionDefinitions.SCORE_MODE = "mode";
DescriptionDefinitions.SCRAVELLING_POI_X = "x";
DescriptionDefinitions.SCRAVELLING_POI_Y = "y";
DescriptionDefinitions.SCROLL_COUNT = "scrollCount";
DescriptionDefinitions.SCROLL_PERCENTAGE = "scrollPercentage";
DescriptionDefinitions.SCROLL_RANGE = "scrollRange";
DescriptionDefinitions.SECOND_CLICK = "secondClick";
DescriptionDefinitions.SEQUENCES = "sequences";
DescriptionDefinitions.SEQUENCE_ID = "id";
DescriptionDefinitions.SEQUENCE_SCORES = "scores";
DescriptionDefinitions.SHAPE = "shape";
DescriptionDefinitions.SHAPES = "shapes";
DescriptionDefinitions.SHAPE_MODE = "mode";
DescriptionDefinitions.SHAPE_STROKE_SIZE = "size";
DescriptionDefinitions.SHOW_DURATION_IN_MILLISECONDS = "showDurationInMilliseconds";
DescriptionDefinitions.SHOW_STICKER = "showSticker";
DescriptionDefinitions.SOURCED_EXPERIENCE_DESCRIPTION_ACTION_URL = "actionUrl";
DescriptionDefinitions.SOURCES = "sources";
DescriptionDefinitions.SOURCE_FORMAT = "format";
DescriptionDefinitions.SOURCE_ID = "id";
DescriptionDefinitions.SOURCE_QUALITY = "quality";
DescriptionDefinitions.SOURCE_TYPE = "type";
DescriptionDefinitions.SOURCE_URL = "url";
DescriptionDefinitions.SPRITES = "sprites";
DescriptionDefinitions.SPRITE_FORMAT = "format";
DescriptionDefinitions.SPRITE_KIND = "kind";
DescriptionDefinitions.SPRITE_QUALITY = "quality";
DescriptionDefinitions.SPRITE_SIZE_COMPUTATION = "sizeComputation";
DescriptionDefinitions.SPRITE_STATE_SIZE = "size";
DescriptionDefinitions.SPRITE_URL = "url";
DescriptionDefinitions.STATE = "state";
DescriptionDefinitions.STATES = "states";
DescriptionDefinitions.STEPS = "steps";
DescriptionDefinitions.STRIPES = "stripes";
DescriptionDefinitions.STRIPES_BACKGROUND_COLOR = "backgroundColor";
DescriptionDefinitions.STROKE = "stroke";
DescriptionDefinitions.STYLES = "styles";
DescriptionDefinitions.SURFACE = "surface";
DescriptionDefinitions.SVG = "svg";
DescriptionDefinitions.SYNTHETIC = "synthetic";
DescriptionDefinitions.TAG = "tag";
DescriptionDefinitions.TEASING = "teasing";
DescriptionDefinitions.TEASING_ANIMATION_DURATION_IN_MILLISECONDS = "animationDurationInMilliseconds";
DescriptionDefinitions.TEASING_IDLE_DURATION_IN_MILLISECONDS = "idleDurationInMilliseconds";
DescriptionDefinitions.TEASING_PERCENTAGE = "percentage";
DescriptionDefinitions.TEASING_TYPE = "type";
DescriptionDefinitions.TEXT = "text";
DescriptionDefinitions.TEXTS = "texts";
DescriptionDefinitions.TEXT_KIND = "kind";
DescriptionDefinitions.TEXT_STATE_SIZE = "size";
DescriptionDefinitions.TEXT_STATE_SIZE_COMPUTATION = "sizeComputation";
DescriptionDefinitions.THEME = "theme";
DescriptionDefinitions.THICKNESS_PERCENTAGE = "thicknessPercentage";
DescriptionDefinitions.TO = "to";
DescriptionDefinitions.TOP_LEFT = "topLeft";
DescriptionDefinitions.TOP_OFFSET_PERCENTAGE = "topOffsetPercentage";
DescriptionDefinitions.TRACKS = "tracks";
DescriptionDefinitions.TRACK_ID = "id";
DescriptionDefinitions.TRANSITION = "transition";
DescriptionDefinitions.UNDERSTITIAL_PERCENTAGE = "percentage";
DescriptionDefinitions.VALUE = "value";
DescriptionDefinitions.VERTICES = "vertices";
DescriptionDefinitions.VIEW_DURATION_IN_MILLISECONDS = "viewDurationInMilliseconds";
DescriptionDefinitions.VIEW_PERCENTAGE = "viewPercentage";
DescriptionDefinitions.VISIBLE = "visible";
DescriptionDefinitions.WHERE = "where";
DescriptionDefinitions.WIDTH = "width";
DescriptionDefinitions.WIDTH_COMPUTATION = "widthComputation";
DescriptionDefinitions.WIDTH_PERCENTAGE = "widthPercentage";
DescriptionDefinitions.X_ALIGN = "xAlign";
DescriptionDefinitions.Y_ALIGN = "yAlign";
DescriptionDefinitions.ZONES = "zones";
DescriptionDefinitions.ZONE_ACTION_ID = "actionId";
DescriptionDefinitions.ZOOM_FACTOR = "zoomFactor";

/*
 * Copyright (C) 2016-2018 KoppaSoft.
 *
 * The code hereby is the private full property of the KoppaSoft company, Paris, France.
 *
 * You have no right to re-use or modify it. There are no open-source, nor free license
 * attached to it!
 */

DescriptionDefinitions.ScoreModes = {
  Frozen: "frozen",
  Scrolling: "scrolling",
  Time: "time",
  InScroll: "inscroll",
  Action: "action",
};

DescriptionDefinitions.ActionModes = {
  New: "new",
  Same: "same",
  Experience: "experience",
  Sequence: "sequence",
};

DescriptionDefinitions.ProgramTransitionTypes = {
  AlphaIn: "alphaIn",
  Fading: "fading",
  Cut: "cut",
  CoverUp: "coverUp",
  CoverDown: "coverDown",
  CoverLeft: "coverLeft",
  CoverRight: "coverRight",
  UncoverUp: "uncoverUp",
  UncoverDown: "uncoverDown",
  UncoverLeft: "uncoverLeft",
  UncoverRight: "uncoverRight",
  TranslateUp: "translateUp",
  TranslateDown: "translateDown",
  TranslateLeft: "translateLeft",
  TranslateRight: "translateRight",
  RevealUp: "revealUp",
  RevealDown: "revealDown",
  RevealLeft: "revealLeft",
  RevealRight: "revealRight",
};

DescriptionDefinitions.TransitionTypes = {
  None: "none",
  Default: "default",
  Opacity: "opacity",
  Pixelation: "pixelation",
  Grey: "grey",
  Noise: "noise",
};

DescriptionDefinitions.AnimationTypes = {
  FirstClick: "firstClick",
  SecondClick: "secondClick",
};

DescriptionDefinitions.AnimationNames = {
  None: "none",
  Default: "default",
};

DescriptionDefinitions.InputTypes = {
  Scrolling: "scrolling",
  Visibility: "visibility",
  Display: "display",
};

DescriptionDefinitions.InputWheres = {
  Down: "down",
  Up: "up",
};

DescriptionDefinitions.InputSurfaces = {
  Full: "full",
  Visible: "visible",
};

DescriptionDefinitions.SourceTypes = {
  Image: "image",
  Video: "video",
};

DescriptionDefinitions.SpriteKinds = {
  Static: "static",
  AnimatedGif: "animatedGif",
  Svg: "svg",
  Video: "video",
  Dynamic: "dynamic",
  Draw: "draw",
  Free: "free",
};
DescriptionDefinitions.isSpriteAnimated = function (kind) {
  return kind !== DescriptionDefinitions.SpriteKinds.Static && kind !== DescriptionDefinitions.SpriteKinds.Svg;
};
DescriptionDefinitions.isSpriteExternal = function (kind) {
  return (
    kind === DescriptionDefinitions.SpriteKinds.Dynamic ||
    kind === DescriptionDefinitions.SpriteKinds.Draw ||
    kind === DescriptionDefinitions.SpriteKinds.Free
  );
};

DescriptionDefinitions.LayerModes = {
  Split: "split",
  Merge: "merge",
};

// All the canvas composite operations are listed and described at https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
DescriptionDefinitions.CompositeOperations = {
  SourceOver: "source-over",
  SourceIn: "source-in",
  SourceOut: "source-out",
  SourceAtop: "source-atop",
  DestinationOver: "destination-over",
  DestinationIn: "destination-in",
  DestinationOut: "destination-out",
  DestinationAtop: "destination-atop",
  Lighter: "lighter",
  Copy: "copy",
  Xor: "xor",
  Multiply: "multiply",
  Screen: "screen",
  Overlay: "overlay",
  Darken: "darken",
  Lighten: "lighten",
  ColorDodge: "color-dodge",
  ColorBurn: "color-burn",
  HardLight: "hard-light",
  SoftLight: "soft-light",
  Difference: "difference",
  Exclusion: "exclusion",
  Hue: "hue",
  Saturation: "saturation",
  Color: "color",
  Luminosity: "luminosity",
};

DescriptionDefinitions.RenderModes = {
  Crop: "crop",
  SmartCrop: "smartcrop",
  Inbox: "inbox",
  Map: "map",
  Fill: "fill",
  Outbox: "outbox",
};

DescriptionDefinitions.Interpolators = {
  Linear: "linear",
  EaseInQuart: "easeInQuart",
  EaseOutQuart: "easeOutQuart",
  EaseInOutQuart: "easeInOutQuart",
  EaseInOutQuad: "easeInOutQuad",
  EaseInOutBack: "easeInOutBack",
  EaseInBackOutQuad: "easeInBackOutQuad",
  EaseInQuadOutBack: "easeInQuadOutBack",
  EaseInOutSine: "easeInOutSine",
  EaseInOutCubic: "easeInOutCubic",
  EaseInCubic: "easeInCubic",
  EaseOutCubic: "easeOutCubic",
  EaseInCirc: "easeInCirc",
  EaseOutCirc: "easeOutCirc",
  EaseInQuad: "easeInQuad",
  EaseOutQuad: "easeOutQuad",
  EaseInExpo: "easeInExpo",
  EaseOutExpo: "easeOutExpo",
  EaseInOutSmooth: "easeInOutSmooth",
  EaseInOutSmoothBack: "easeInOutSmoothBack",
  EaseInSmoothBack: "easeInSmoothBack",
  EaseOutSmoothBack: "easeOutSmoothBack",
  EaseInElastic: "easeInElastic",
  EaseOutElastic: "easeOutElastic",
  EaseInBounce: "easeInBounce",
  EaseOutBounce: "easeOutBounce",
  EaseInFast45OutMaxiSmooth: "easeInFast45OutMaxiSmooth",
  EaseInFast36OutMaxiSmooth: "easeInFast36OutMaxiSmooth",
  EaseInFast16OutMaxiSmooth: "easeInFast16OutMaxiSmooth",
  EaseInMaxiSmoothOutFast16: "easeInMaxiSmoothOutFast16",
  EaseInFast20OutVerySmooth: "easeInFast20OutVerySmooth",
  EaseIn60OutSmooth: "easeIn60OutSmooth",
  EaseInOutMaxSmooth: "easeInOutMaxSmooth",
  EaseOutQuint: "easeOutQuint",
  EaseInOutExpo: "easeInOutExpo",
  EaseInFast16OutExpo: "easeInFast16OutExpo",
};

DescriptionDefinitions.LengthUnits = {
  Pixel: "pixel",
  Percent: "percent",
};

DescriptionDefinitions.ParticleDimensionComputations = {
  Width: "width",
  Height: "height",
  Smallest: "smallest",
  Largest: "largest",
};

DescriptionDefinitions.Alignments = {
  Start: "start",
  Middle: "middle",
  End: "end",
};

DescriptionDefinitions.Orientations = {
  Horizontal: "horizontal",
  Vertical: "vertical",
};

DescriptionDefinitions.BorderTypes = {
  None: "none",
  Stroke: "stroke",
  Blur: "blur",
};

DescriptionDefinitions.RichTextFontSizeComputations = {
  Absolute: "absolute",
  Width: "width",
  Height: "height",
  Smallest: "smallest",
  Largest: "largest",
};

DescriptionDefinitions.TextSizeComputations = {
  /**
   * The text height is expressed in pixels, independently from the canvas width and height.
   */
  Absolute: "absolute",
  /**
   * The text height is computed from the full text width, which is itself computed according to the canvas width.
   */
  HorizontalFull: "horizontalFull",
  /**
   * The text height will be computed according to the canvas width.
   */
  HorizontalSingle: "horizontalSingle",
  /**
   * The text height is computed from the full text width, which is itself computed according to the canvas height.
   */
  VerticalFull: "verticalFull",
  /**
   * The text height will be computed according to the canvas height.
   */
  VerticalSingle: "verticalSingle",
};

// The possible external asset types
DescriptionDefinitions.ExternalAssetTypes = {
  Font: "font",
  Svg: "svg",
  Animation: "animation",
  Image: "image",
  Video: "video",
  JavaScript: "javaScript",
};

DescriptionDefinitions.SpriteDimensionComputations = {
  Horizontal: "horizontal",
  Vertical: "vertical",
};

DescriptionDefinitions.TextKinds = {
  Simple: "simple",
  Rich: "rich",
};

DescriptionDefinitions.Shapes = {
  PolyLine: "polyline",
  Rectangle: "rectangle",
  Ellipse: "ellipse",
};

DescriptionDefinitions.ShapeModes = {
  Fill: "fill",
  Stroke: "stroke",
  Dash: "dash",
  Clip: "clip",
};

DescriptionDefinitions.TextParticles = "texts";
DescriptionDefinitions.ShapeParticles = "shapes";
DescriptionDefinitions.SpriteParticles = "sprites";
DescriptionDefinitions.Particles = [
  DescriptionDefinitions.TextParticles,
  DescriptionDefinitions.ShapeParticles,
  DescriptionDefinitions.SpriteParticles,
];

DescriptionDefinitions.PlayerModes = {
  Always: "always",
  Repeat: "repeat",
  Once: "once",
};

DescriptionDefinitions.TeasingTypes = {
  None: "none",
  Hot: "hot",
  Remain: "remain",
};

DescriptionDefinitions.HotZoneTypes = {
  None: "none",
  Magnet: "magnet",
  Below: "below",
};

// The call-to-acton icons
DescriptionDefinitions.CtaIcons = {
  Plus: "plus",
  Info: "info",
  Hand: "hand",
  Eye: "eye",
  Caddy: "caddy",
  Basket: "basket",
};

// The call-to-acton themes
DescriptionDefinitions.CtaThemes = {
  Light: "light",
  Dark: "dark",
};

// The call-to-acton sizes
DescriptionDefinitions.CtaSizes = {
  XSmall: "xsmall",
  Small: "small",
  Medium: "medium",
  Large: "large",
  XLarge: "xlarge",
};

DescriptionDefinitions.Null = "null";

/**
 * The default description values.
 */
DescriptionDefinitions.Defaults = {
  useDevicePixelRatio: true,
  reverse: false,
  inRead: true,
  loopCount: 1,
  color: "#D9422B",
  freePercentage: 10,
  activationPercentage: 75,
  displayPercentage: 75,
  showDurationInMilliseconds: 2000,
  viewDurationInMilliseconds: 1000,
  viewPercentage: 50,
  illustrationMinimumDisplayInMilliseconds: 1000,
  promotionMinimumDisplayInMilliseconds: 1000,
  maximumDisplayCount: Number.MAX_VALUE,
  steps: 15,
  gyroscopic: true,
  percentage: 50,
  gravityPercentage: 50,
  extendable: true,
  spriteColor: "#000000FF",
  transition: DescriptionDefinitions.TransitionTypes.Default,
  inScrollStripesVisible: true,
  inScrollStripesTopOffsetPercentage: 2.5,
  inScrollStripesWidthPercentage: 90,
  inScrollStripesThicknessPercentage: 0.4,
  inScrollStripesBackgroundColor: "#808080FF",
  inScrollStripesForegroundColor: "#FFFFFFFF",
  navigationZonePercentage: 20,
  scrollPercentage: 33,
  scrollCount: 3,
  reachPercentage: 60,
};

export default DescriptionDefinitions;
