import React, { useEffect } from "react";
import { useHotkey } from "context";
import historyStore, { HistoryType } from "~/services/HistoryStore";
import { getOS } from "~/util/Utils";
import { Tooltip } from "components";
import ArrowGoBackLineIcon from "remixicon-react/ArrowGoBackLineIcon";
import ArrowGoForwardLineIcon from "remixicon-react/ArrowGoForwardLineIcon";

import i18n from "i18next";
import style from "./UndoRedo.module.scss";
import useStores from "~/hooks/useStores";
import Core from "../../../../../../services/Core";
import References from "../../../../../../util/References";
import { Button } from "@ogury/design-system";

export default function UndoRedo() {
  const { setActiveTreeNodeId, getDeepNodeInstanceById, regenerateUiStore } = useStores();
  const { hotkey, isHotkeyPressed } = useHotkey();

  function undo() {
    let lastHistory = historyStore.undo();
    restoreHistory(lastHistory, "previous");
  }

  function redo() {
    let nextHistory = historyStore.redo();
    restoreHistory(nextHistory, "current");
  }

  function restoreHistory(history, valueFieldName) {
    // History is an input value or visibility change
    if (
      history.type === HistoryType.Value ||
      history.type === HistoryType.Enabled ||
      history.type === HistoryType.Visibility
    ) {
      let input = getDeepNodeInstanceById(history.id);

      if (input) {
        if (history.type === HistoryType.Value) {
          const { value, metas } = history[valueFieldName];
          input.setValue(value, false, metas, false);
        } else if (history.type === HistoryType.Enabled) {
          input.setEnabled(history[valueFieldName], false, false);
        } else if (history.type === HistoryType.Visibility) {
          input.setConditionVisiblityIsToggled(history[valueFieldName], false);
        }
      }
    }
    // History is collection move
    else if (history.type === HistoryType.Collection) {
      Core.restoreCollection(history[valueFieldName]);
      regenerateUiStore();
    }
    setActiveTreeNodeId(history.activeInput);
    document.getElementById(References.CENTER_PANEL_ID).scrollTop = history.centerScrollTop;
  }

  useEffect(() => {
    if (historyStore.canRedo() && isHotkeyPressed("meta+shift+z,ctrl+y")) {
      return redo();
    }
    if (historyStore.canUndo() && isHotkeyPressed("ctrl+z,meta+z")) {
      return undo();
    }
  }, [hotkey]);

  function getUndoTooltipHotkeyLabel() {
    let tooltip = i18n.t("header.button.undoTooltip");
    if (getOS() === "macos") {
      tooltip = i18n.t("header.button.undoTooltipMAC");
    }
    return tooltip;
  }

  function getRedoTooltipHotkeyLabel() {
    let tooltip = i18n.t("header.button.redoTooltip");
    if (getOS() === "macos") {
      tooltip = i18n.t("header.button.redoTooltipMAC");
    }
    return tooltip;
  }

  return (
    <div className={style.container}>
      <Tooltip content={getUndoTooltipHotkeyLabel()}>
        <Button
          size="small"
          type="tertiary"
          style={{ marginRight: 4, width: 24, height: 24 }}
          disabled={!historyStore.canUndo()}
          onClick={undo}
        >
          <ArrowGoBackLineIcon />
        </Button>
      </Tooltip>
      <Tooltip content={getRedoTooltipHotkeyLabel()}>
        <Button
          size="small"
          type="tertiary"
          style={{ width: 24, height: 24 }}
          disabled={!historyStore.canRedo()}
          onClick={redo}
        >
          <ArrowGoForwardLineIcon />
        </Button>
      </Tooltip>
    </div>
  );
}
