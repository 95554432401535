import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import { Experience } from "@ogury/motionly-ws-api/ws";

import { Badge } from "@ogury/design-system";
import { useTranslation } from "react-i18next";

export const tagTypes = {
  NEUTRAL: "neutral",
  PROCESSING: "processing",
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  GEEKBLUE: "geekblue",
  DEFAULT: "default",
};

export default function ExperienceStatusTag({ status = "" }) {
  const [t] = useTranslation();

  const tagInfo = useMemo(() => {
    switch (status) {
      case Experience.StateEnum.Draft:
        return { label: Experience.StateEnum.Draft, status: tagTypes.DEFAULT };
      case Experience.StateEnum.Idle:
        return { label: Experience.StateEnum.Idle, status: tagTypes.PROCESSING };
      case Experience.StateEnum.Published:
        return { label: t("header.status.published"), status: tagTypes.WARNING };
      case Experience.StateEnum.Live:
        return { label: Experience.StateEnum.Live, status: tagTypes.SUCCESS };
      case Experience.StateEnum.Archived:
        return { label: Experience.StateEnum.Archived, status: tagTypes.ERROR };
      default:
        return { label: Experience.StateEnum.Draft, status: tagTypes.DEFAULT };
    }
  }, [status]);

  return <Badge status={tagInfo.status} text={tagInfo.label} />;
}

ExperienceStatusTag.propTypes = {
  status: PropTypes.string,
};
