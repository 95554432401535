import React, { useContext, useEffect, useState } from "react";
import { Loader, Divider } from "components";
import { AccessibilityContext, Side } from "context/AccessibilityContext";
import { Tabs, Upload, Col, Row, Typography, TextOverflow, Button, theme } from "@ogury/design-system";
import CopyIcon from "remixicon-react/FileCopy2LineIcon";
import DeleteIcon from "remixicon-react/DeleteBinLineIcon";
import { useExperience } from "~/context";
import { useTranslation } from "react-i18next";
import { getAssets } from "~/util/ExperienceDescription";
import Api from "~/util/Api";
import style from "./FileManager.module.scss";

const TABS = {
  FILES: "files",
};

export default function FileManager() {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.LAYERS);
  const [filesList, setFilesList] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const { experience } = useExperience();

  const [accessibilityState] = useContext(AccessibilityContext);

  const isLoading =
    accessibilityState.loading.active === true && accessibilityState.loading.sides.includes(Side.Builder);

  useEffect(() => {
    if (!experience.description || experience.dirty || filesList.length > 0) {
      return;
    }
    const assets = getAssets(experience.description);
    const filesListFromAssets = assets.map(asset => ({
      ...asset,
      uid: asset.url,
      status: "done",
      canRemove: false,
    }));
    setFilesList(filesListFromAssets);
  }, [experience]);

  function updateFileState(fileUid, updates) {
    setFilesList(prevState => {
      const fileExists = prevState.some(file => file.uid === fileUid);

      if (fileExists) {
        return prevState.map(file => (file.uid === fileUid ? { ...file, ...updates } : file));
      } else {
        return [...prevState, { uid: fileUid, ...updates }];
      }
    });
  }

  function removeFileByUid(fileUid) {
    setFilesList(prevState => prevState.filter(file => file.uid !== fileUid));
  }

  async function processFile(file) {
    const { uid, name, type, size } = file;

    try {
      setIsFileLoading(true);
      updateFileState(uid, { status: "processing", message: "Uploading", name });
      const { url } = await Api.uploadTemporaryFile(type, encodeURIComponent(name), file);
      updateFileState(uid, { status: "done", name, size, url, canRemove: true });
    } catch (error) {
      updateFileState(uid, { status: "error", canRemove: true });
      console.warn(error);
    } finally {
      setIsFileLoading(false);
    }
  }

  function copyToClipboard(url) {
    navigator.clipboard.writeText(url);
  }

  function renderFileTree() {
    return (
      <div className={style.container}>
        <Upload.Dragger
          data-testid="file-uploader"
          beforeUpload={processFile}
          accept={[".jpeg", ".jpg", ".png", ".lottie", ".json", ".mp4"]}
          action={() => "false"}
          showUploadList={false}
          customRequest={() => {}}
        />
        {isFileLoading && <Loader overlay />}
        <Divider />
        {filesList.map(file => (
          <Row key={file.uid} gutter={[parseInt(theme.spacing.space_xs)]} justify="space-between">
            <Col>
              <Typography.P2Regular
                color={file.status === "error" ? theme.colors.destructive.content : theme.colors.primary.content}
              >
                <TextOverflow maxWidth="620px" value={file.name} />
              </Typography.P2Regular>
            </Col>
            <Col>
              {file.status === "done" && (
                <Button
                  data-testid="btn-copy-url-file"
                  icon={<CopyIcon />}
                  onClick={() => copyToClipboard(file.url)}
                  type="tertiary"
                  iconPosition="iconOnly"
                  size="small"
                />
              )}
              {file.canRemove && (
                <Button
                  data-testid="btn-remove-file"
                  icon={<DeleteIcon />}
                  onClick={() => removeFileByUid(file.uid)}
                  type="tertiary"
                  iconPosition="iconOnly"
                  size="small"
                />
              )}
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  function onClickOnSettingsTab(tabName) {
    setActiveTab(tabName);
  }

  return (
    <div>
      <Tabs
        data-testid="left-panel-tabs"
        tabBarStyle={{ paddingLeft: 16 }}
        onTabClick={onClickOnSettingsTab}
        items={[
          {
            key: TABS.FILES,
            label: t("leftPanel.files"),
            children: renderFileTree(),
          },
        ]}
      />
      {isLoading === true && <Loader overlay />}
    </div>
  );
}
