import i18n from "i18next";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { datadogRum } from "@datadog/browser-rum";
import { Button, Dropdown, Modal, ModalClosableFooter, Typography, useAppNotification } from "@ogury/design-system";
import { Experience } from "@ogury/motionly-ws-api/ws";
import { FileInput, IconNames, Icons } from "components";
import { useExperience, useHotkey } from "context";
import { AccessibilityContext, Side } from "context/AccessibilityContext";
import { useCreativeSettingsContext } from "context/CreativeSettingsContext";
import { usePsdStore } from "context/PsdStoreContext";
import More2FillIcon from "remixicon-react/More2FillIcon";
import Scales2FillIcon from "remixicon-react/Scales2FillIcon";
import useStores from "~/hooks/useStores";
import Core from "~/services/Core";
import experienceService from "~/services/ExperienceService";
import HistoryStore from "~/services/HistoryStore";
import unitService from "~/services/UnitService";
import { AD_UNIT_PREFIXES } from "~/util/AdUnitPrefixes";
import Api from "~/util/Api";
import { buildInternalAssetUrl, DBNAME, NOTIFICATION_PLACEMENT, STORENAME, TRANSACTIONMODE } from "~/util/Constant";
import DatadogEvents from "~/util/DatadogEvents";
import Debug from "~/util/Debug";
import References from "~/util/References";
import { downloadBlob, isEmpty } from "~/util/Utils";
import style from "./HeaderButtons.module.scss";
import { CreativeWeightModal, DuplicateExperienceModal, SaveButton } from "./components";

const HeaderButtons = ({ parentProxy, experienceData, showExperienceButton, onAfterSave, saveSeed, onSave }) => {
  const notification = useAppNotification();
  const { creativeSettings } = useCreativeSettingsContext();
  const { psdStore } = usePsdStore();
  const { experience, setExperience } = useExperience({ ...experienceData });
  const { hotkey, isHotkeyPressed } = useHotkey();
  const { uiStore, activeTreeNodeId, regenerateUiStore, setActiveTreeNodeId } = useStores();
  const [accessibilityState, accessibilityActions] = useContext(AccessibilityContext);

  const [confirmSave, setConfirmSave] = useState(false);
  const [confirmImport, setConfirmImport] = useState(false);
  const [isDebug, setIsDebug] = useState(Debug.isDebug);
  const [showCreativeWeightModal, setShowCreativeWeightModal] = useState(false);
  const [binaryWeightButtonAvailable, setBinaryWeightButtonAvailable] = useState(true);
  const [showDuplicateExperienceModal, setShowDuplicateExperienceModal] = useState(false);

  const currentSiteId = useMemo(() => Api.getSiteId(), []);

  // TODO when doing the future refactorization of the Parent Proxy in a hook, remove this to call the hook.
  const [canSaveExperience, setCanSaveExperience] = useState(true);

  const history = useHistory();
  const location = useLocation();
  const isFormDirty = HistoryStore.getDirty() || experience.dirty || !experience?.id;
  const { name: experienceName, state: experienceState } = experience;

  const keyValue = undefined;

  async function updateCanSaveExperience() {
    const parentResponse = await parentProxy?.call("canSaveExperience");
    if (parentResponse !== undefined && (parentResponse === true || parentResponse === false)) {
      setCanSaveExperience(parentResponse);
    }
  }

  useEffect(() => {
    if (saveSeed) {
      validateAndSaveExperience();
    }
  }, [saveSeed]);

  useEffect(() => {
    if (isHotkeyPressed("ctrl+shift+d")) {
      Debug.toggleDebug();
      notification.info({
        message: Debug.isDebug() ? "Debug enabled" : "Debug disabled",
        placement: NOTIFICATION_PLACEMENT,
      });
      setIsDebug(Debug.isDebug());
      Debug.logAppConstants();
    }
  }, [hotkey]);

  useEffect(() => {
    if (parentProxy !== null) {
      updateCanSaveExperience();
    }
  }, [parentProxy]);

  useEffect(() => {
    setExperience({ ...experience, ...experienceData });
  }, [experienceData.dirty, experienceData.name]);

  async function exportInputs() {
    accessibilityActions.setLoading({
      active: true,
      text: "Exporting the creative input parameters…",
    });
    try {
      const rootInstance = Core.getDeepNodeById(References.ROOT_INPUT_ID, true);
      const { exportValues, errors } = await rootInstance.generateExportValues({
        assetsBaseUrl: experience?.assetsBaseUrl,
        resolveAssets: true,
      });

      Debug.log("Number of input's errors while exporting : " + errors.length);
      const json = JSON.stringify(exportValues, null, "\t");
      const blob = new Blob([json], { type: "application/json" });
      downloadBlob(
        blob,
        isEmpty(experienceName) ? Core.getCurrentTemplate().id + "-inputs.json" : experienceName + ".json"
      );
    } catch (error) {
      notification.error({
        message: error.message,
        description: "Export failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  function importInputs(file) {
    accessibilityActions.setLoading({
      active: true,
      text: i18n.t("accessibility.importingInputs"),
    });
    const reader = new FileReader();
    reader.onload = async function (event) {
      try {
        let importValues;

        const string = event.target.result;
        importValues = JSON.parse(string);

        await Core.generateDeepStoreFromValues(importValues);
        regenerateUiStore();
        setExperience({ ...experience, dirty: true });
        notification.success({
          message: i18n.t("notification.importDone"),
          placement: NOTIFICATION_PLACEMENT,
        });
      } catch (error) {
        Debug.error("Can't import JSON data", error);
        notification.error({
          message: i18n.t("notification.importFailed"),
          placement: NOTIFICATION_PLACEMENT,
        });
      } finally {
        accessibilityActions.setLoading({ active: false });
      }
    };
    reader.readAsText(file);
  }

  async function invokeParent(hookName, parameter) {
    return await parentProxy.call(hookName, parameter);
  }

  async function onShowExperience() {
    if (parentProxy !== undefined) {
      try {
        await invokeParent("onShowExperience", {
          experienceId: experience.id,
        });
      } catch (error) {
        notification.error({
          message: i18n.t("error.showExperience.message"),
          description: error.message,
          placement: NOTIFICATION_PLACEMENT,
          duration: 0,
        });
      }
    }
  }

  async function saveBespokeCreativeDescription(experience) {
    accessibilityActions.setLoading({
      active: true,
      text: i18n.t("accessibility.validatingExperience"),
    });
    try {
      const updatedExperience = await Api.setDescription(experience.id, experience.description);
      if (experienceData.dirty) {
        await Api.renameExperience(experience.id, experienceData.name);
      }
      history.replace("/expert/" + experience.id + (window.location.search || location.search));
      notification.success({
        message: "The creative has been successfully saved",
        placement: NOTIFICATION_PLACEMENT,
      });
      HistoryStore.resetDirty();
      setExperience({ ...updatedExperience, dirty: false });
      onAfterSave();
    } catch (error) {
      notification.error({
        message: error.message,
        description: "Saving failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  async function validateAndSaveExperience(showDuplicationModal = false) {
    if (isEmpty(experienceName) === true) {
      return notification.error({
        message: i18n.t("error.saveExperience.nameRequired"),
        description: "Saving failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    }
    accessibilityActions.setLoading({
      active: true,
      text: i18n.t("accessibility.validatingExperience"),
    });

    try {
      try {
        const rootInstance = Core.getDeepNodeById(References.ROOT_INPUT_ID, true);
        const { exportValues, errors } = await rootInstance.generateExportValues({
          assetsBaseUrl: experience?.assetsBaseUrl,
          showErrors: true,
        });

        // Show the errors if existings
        regenerateUiStore();

        if (errors.length > 0) {
          throw new Error(i18n.t("error.saveExperience.inputsIssues"));
        } else {
          if (showDuplicationModal) {
            const availableUnits = await experienceService.getAvailableUnitsForDuplication();
            if (availableUnits?.length) {
              return setShowDuplicateExperienceModal(true);
            }
          }
          accessibilityActions.setLoading({
            active: true,
            text: i18n.t("accessibility.savingExperience"),
          });
          const newExperience = await saveExperience(exportValues);
          HistoryStore.resetDirty();
          setBinaryWeightButtonAvailable(false);
          // 3seconds timeout is the time the server took to invalidate the cache of the headless browser that computes the binary weight.
          setTimeout(() => {
            setBinaryWeightButtonAvailable(true);
          }, 3200);
          return newExperience;
        }
      } catch (error) {
        throw new Error(i18n.t("error.saveExperience.extractData", { reason: error.message }));
      }
    } catch (error) {
      notification.error({
        message: error.message,
        description: "Saving failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }
  function savePSDFiles(experienceId) {
    //Save PSD files
    if (psdStore.length > 0) {
      const request = window.indexedDB.open(DBNAME, 1);

      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction([STORENAME], TRANSACTIONMODE.RW);

        const objectStore = transaction.objectStore(STORENAME);
        const requestAll = objectStore.getAll();
        requestAll.onsuccess = event => {
          const allDatas = event.target.result;
          let fileKeysToDelete = allDatas.filter(x => x.experienceId === store[0].experienceId).map(file => file.PSD);
          if (fileKeysToDelete.length > 0) {
            for (const key of fileKeysToDelete) {
              transaction.objectStore(STORENAME).delete(key);
            }
          }
          try {
            for (const file of store) {
              file.experienceId = experienceId;
              const request = objectStore.put(file, keyValue);
              request.onerror = () => {
                Debug.error("Error while inserting PSD object in indexedDB");
              };
            }
          } catch (error) {
            // TODO: handle the error and show it to the user
            Debug.error("Error while inserting PSD object in indexedDB", error.name, error.message);
          }
        };
        db.close();
      };

      request.onupgradeneeded = function (event) {
        const db = event.target.result;
        db.createObjectStore(STORENAME, {
          keyPath: "PSD",
          autoIncrement: true,
        });
      };
    }
  }

  async function saveExperience(exportValues) {
    let payload = {
      map: exportValues,
      experienceInput: {
        mode: "experience",
        name: experienceName,
      },
      builderSettings: {
        units: creativeSettings.units,
      },
      units: [{ technicalId: unitService.getCurrentUnitTechnicalId() }],
    };

    // BEGIN Hook "onBeforeBuild", send payload before building the experience
    if (parentProxy !== undefined) {
      payload = await invokeParent("onBeforeBuild", payload);
    }
    // END Hook
    let build = await Api.build(
      Core.getCurrentTemplate().id,
      Core.getCurrentTemplate().version,
      payload,
      experience?.id
    );

    // BEGIN Hook "onAfterBuild", send build result after building the experience
    let experienceAssetsBaseUrl = buildInternalAssetUrl(build.storagePath);

    if (parentProxy !== undefined) {
      build = await invokeParent("onAfterBuild", build);
      const experienceUrls = await invokeParent("onExperienceUrls", build.storagePath);
      if (experienceUrls !== undefined) {
        experienceAssetsBaseUrl = experienceUrls["assetsBaseUrl"];
      }
    }
    await experienceService.attachToSite(currentSiteId, build.experienceId);
    // END Hook

    const inputsToReplace = build?.inputsMap;

    if (inputsToReplace !== undefined) {
      // Replacing in deep store the input values with URL values
      for (let key in inputsToReplace) {
        const inputInstance = Core.getDeepNodeById(key, true);
        if (inputInstance !== undefined) {
          inputInstance.setValue(inputsToReplace[key], true, undefined, false);
        }
      }
    }

    const newExperience = {
      ...experience,
      id: build.experienceId,
      data: build.data,
      assetsBaseUrl: experienceAssetsBaseUrl,
      description: build.description,
    };
    setExperience(newExperience);

    if (onSave) {
      onSave(newExperience);
    }

    //Save PSD Files
    savePSDFiles(build.experienceId);

    history.replace("/experience/" + build.experienceId + (window.location.search || location.search));
    notification.success({
      message: "The creative has been successfully saved",
      placement: NOTIFICATION_PLACEMENT,
    });
    onAfterSave();
    return newExperience;
  }

  async function handleOnSaveFromDuplicateModal(unitsTechnicalIds) {
    setShowDuplicateExperienceModal(false);
    const savedExperience = await validateAndSaveExperience();

    // Only if there is new ad-units to duplicate
    if (unitsTechnicalIds.length) {
      const units = await unitService.getUnits();
      try {
        for (const technicalId of unitsTechnicalIds) {
          const prefix = AD_UNIT_PREFIXES[technicalId] ? `${AD_UNIT_PREFIXES[technicalId]}_` : "";
          const prefixMatch = Object.values(AD_UNIT_PREFIXES).filter(prefix => experience.name.startsWith(prefix));
          const experienceNameWithoutPrefix = experience.name?.split("_");
          experienceNameWithoutPrefix.shift();

          const newExperienceName =
            prefixMatch.length > 0
              ? `${prefix}${experienceNameWithoutPrefix.join("_")}`
              : `${prefix}${experience.name}`;
          const unit = units.find(obj => obj.technicalId === technicalId);
          // Duplication
          accessibilityActions.setLoading({
            active: true,
            text: i18n.t("accessibility.duplicatingExperience", { unit: unit.name }),
          });

          const duplicatedExperience = await experienceService.duplicate(
            savedExperience.id,
            newExperienceName,
            "From builder duplication"
          );
          // Assignation of the new ad-unit
          await experienceService.setUnits(duplicatedExperience.id, [technicalId]);

          // Attachment to the site
          await experienceService.attachToSite(currentSiteId, duplicatedExperience.id);
        }
        datadogRum.addAction(DatadogEvents.DUPLICATION_AFTER_SAVE);
        notification.success({
          message: i18n.t("notification.duplicationDone"),
          placement: NOTIFICATION_PLACEMENT,
        });
      } catch (error) {
        notification.error({
          message: error.message,
          placement: NOTIFICATION_PLACEMENT,
        });
      }
    }

    accessibilityActions.setLoading({ active: false });
  }

  function handleSaveClick() {
    if (experience.origin === Experience.OriginEnum.Synchronized) {
      saveBespokeCreativeDescription(experience);
      return;
    }
    if (experienceState !== Experience.StateEnum.Published) {
      validateAndSaveExperience(currentSiteId && experience?.id === undefined);
      return;
    }

    setConfirmSave(true);
  }

  function handleSaveConfirmClick() {
    setConfirmSave(false);
    validateAndSaveExperience(currentSiteId && experience?.id === undefined);
  }

  const isLoading =
    accessibilityState.loading.active === true && accessibilityState.loading.sides.includes(Side.Buttons);

  const dropdownItems = useMemo(() => {
    const items = [
      ...(experience.id && currentSiteId
        ? [
            {
              key: "1",
              label: i18n.t("header.button.saveAndDuplicate"),
              onClick: () => {
                datadogRum.addAction(DatadogEvents.CLICK_MANUAL_SAVE_AND_DUPLICATE);
                return validateAndSaveExperience(true);
              },
            },
          ]
        : []),
      {
        key: "2",
        icon: <Icons name={IconNames.UploadOutlined.type} />,
        label: <div data-testid="import-button">{i18n.t("header.button.import")}</div>,
        onClick: () => setConfirmImport(true),
      },
      {
        key: "3",
        icon: <Icons name={IconNames.DownloadOutlined.type} />,
        label: (
          <div
            data-testid="export-button"
            onClick={() => {
              if (!isLoading) {
                exportInputs();
              }
            }}
          >
            {i18n.t("header.button.export")}
          </div>
        ),
      },
    ];

    if (isDebug) {
      return [
        ...items,
        ...[
          {
            type: "divider",
          },
          {
            key: "4",
            label: <div onClick={() => Debug.log("DEEP_STORE", Core.getDeepStore())}>Print DEEP_STORE</div>,
          },
          {
            key: "5",
            label: <div onClick={() => Debug.log("UI_STORE", uiStore)}>Print UI_STORE (ui)</div>,
          },
          {
            key: "6",
            label: <div onClick={() => Debug.log("HISTORY_STORE", HistoryStore.getAll())}>Print HISTORY_STORE</div>,
          },
          {
            key: "7",
            label: (
              <div
                onClick={() =>
                  Debug.log("Active instance " + activeTreeNodeId, Core.getDeepNodeById(activeTreeNodeId, true))
                }
              >
                Print active instance
              </div>
            ),
          },
          {
            key: "8",
            label: <div onClick={() => Debug.log("Template", Core.getCurrentTemplate())}>Print template</div>,
          },
          {
            key: "9",
            label: <div onClick={() => Debug.log("Experience", experience)}>Print experience</div>,
          },
        ],
      ];
    }
    return items;
  }, [isDebug, experience]);

  function handleOnGotoBuilder(instanceId) {
    setShowCreativeWeightModal(false);
    setActiveTreeNodeId(instanceId);
  }

  return (
    <div className={style.container}>
      <DuplicateExperienceModal
        open={showDuplicateExperienceModal}
        experienceId={experience?.id}
        onClose={() => setShowDuplicateExperienceModal(false)}
        onSave={handleOnSaveFromDuplicateModal}
        validateAndSaveExperience={validateAndSaveExperience}
      />
      {showCreativeWeightModal && (
        <CreativeWeightModal
          onGotoBuilder={handleOnGotoBuilder}
          experienceId={experience?.id}
          onClose={() => setShowCreativeWeightModal(false)}
        />
      )}
      {experience?.id && (
        <Button
          disabled={!binaryWeightButtonAvailable}
          loading={!binaryWeightButtonAvailable}
          icon={<Scales2FillIcon />}
          iconPosition="left"
          type="secondary"
          onClick={() => setShowCreativeWeightModal(true)}
        >
          {i18n.t("header.button.creativeWeight")}
        </Button>
      )}
      {showExperienceButton === true && (
        <Button type="secondary" onClick={onShowExperience} disabled={isLoading}>
          {i18n.t("header.button.showExperience")}
        </Button>
      )}

      <SaveButton
        onClick={handleSaveClick}
        disabled={!canSaveExperience || isLoading || !isFormDirty}
        parentProxy={parentProxy}
      />

      <Dropdown menu={{ items: dropdownItems }} trigger={["click"]}>
        <Button type="tertiary" iconPosition="iconOnly" data-testid="more-button">
          <More2FillIcon />
        </Button>
      </Dropdown>
      <Modal
        open={confirmSave}
        footer={
          <ModalClosableFooter
            buttonLabel={i18n.t("header.confirmSave.cancel")}
            actions={<Button onClick={handleSaveConfirmClick}>{i18n.t("header.confirmSave.save")}</Button>}
          />
        }
        title={i18n.t("header.confirmSave.title")}
        onCancel={() => setConfirmSave(false)}
        width="520px"
      >
        <Typography.P2Regular>{i18n.t("header.confirmSave.content")}</Typography.P2Regular>
      </Modal>
      <Modal
        open={confirmImport}
        footer={
          <ModalClosableFooter
            buttonLabel={i18n.t("header.confirmImport.cancel")}
            actions={
              <Button data-testid="confirm-import-button">
                <FileInput
                  inputId="import"
                  isButton
                  fileExtensions={[".json"]}
                  mimeTypes="application/json"
                  readDataUrl={false}
                  disabled={isLoading === true}
                  className={isLoading === true ? " disabled" : ""}
                  onProcessing={() => {
                    accessibilityActions.setLoading({
                      active: true,
                      text: "Analyzing the inputs…",
                    });
                  }}
                  onError={error => {
                    notification.error({
                      message: "An unexpected error occurred. Reason: '" + error.message + "'",
                      description: "Import failed",
                      placement: NOTIFICATION_PLACEMENT,
                      duration: 0,
                    });
                    accessibilityActions.setLoading({ active: false });
                  }}
                  onChanged={({ blob }) => {
                    setConfirmImport(false);
                    importInputs(blob);
                  }}
                >
                  {i18n.t("header.confirmImport.import")}{" "}
                </FileInput>
              </Button>
            }
          />
        }
        title={i18n.t("header.confirmImport.title")}
        onCancel={() => setConfirmImport(false)}
        width="520px"
      >
        <Typography.P2Regular>{i18n.t("header.confirmImport.content")}</Typography.P2Regular>
      </Modal>
    </div>
  );
};

HeaderButtons.propTypes = {
  parentProxy: PropTypes.object,
  experienceData: PropTypes.object,
  showExperienceButton: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default HeaderButtons;
