import qs from "qs";

import MotionlyApiClient from "@ogury/motionly-ws-api/MotionlyApiClient";
import MotionlyApiCompanion from "@ogury/motionly-ws-api/MotionlyApiCompanion";
import { AccessTokenInputs, GenerationHtmlParameters } from "@ogury/motionly-ws-api/ws";

import { USE_STUBS } from "./Constant";
import { getAccessToken, getApiKey, getCredentials, saveCredentials } from "./Credentials";
import { isInIframe } from "./Utils";
import Communication from "./Communication";
import TemplateService from "~/services/TemplateService";

// noinspection JSUnresolvedVariable
const { LightMode } = GenerationHtmlParameters.ThemeEnum;
// noinspection JSUnresolvedVariable
const { None } = GenerationHtmlParameters.NavigationBarEnum;

const ROUTES = {};

function extractQueryStringParameters() {
  // We extract the parameters from the URL
  const splitParameters = window.document.location.search.split("?");
  const tokens = splitParameters === undefined || splitParameters.length < 2 ? [] : splitParameters[1].split("&");
  const parameters = {};
  tokens.forEach(token => {
    const innerTokens = token.split("=");
    parameters[innerTokens[0]] = decodeURIComponent(innerTokens[1]);
  });
  return parameters;
}

function getUrlParameter(parameterName) {
  const parameters = qs.parse(location.search || window.location.search, {
    ignoreQueryPrefix: true,
  });
  return parameters && (parameters[parameterName] ? parameters[parameterName] : undefined);
}

function computeFlavor() {
  return getUrlParameter("flavor");
}

// Returns the site ID if the builder has been triggered to create a creative inside a site.
function getSiteId() {
  return getUrlParameter("siteId");
}

// Returns the ratio if the builder has been triggered to create a creative inside a site.
function getRatioFromManager() {
  const ratio = getUrlParameter("ratio");
  if (ratio !== undefined) {
    return parseFloat(ratio);
  }
  return undefined;
}

function getAdUnitTechnicalIdFromManager() {
  return getUrlParameter("adUnitTechnicalId");
}

function getTemplateRevisionFromManager() {
  return getUrlParameter("templateRevision");
}

// Returns the site ID if the builder has been triggered to create a creative inside a site.
function getExperienceNameFromManager() {
  const experienceName = getUrlParameter("experienceName");

  if (experienceName !== undefined) {
    try {
      return decodeURIComponent(experienceName);
    } catch (error) {
      console.warn("An error occured while trying to get the experience name", error);
    }
  }
  return undefined;
}

export default {
  ROUTES,
  isInStudioFlavor: () => {
    return computeFlavor() === "studio";
  },
  getSiteId,
  getTemplateRevisionFromManager,
  getRatioFromManager,
  getAdUnitTechnicalIdFromManager,
  getExperienceNameFromManager,
  initialize: async () => {
    const parameters = extractQueryStringParameters();
    const isDebug = false;
    ROUTES.API_URL =
      parameters["serverBaseUrl"] !== undefined ? parameters["serverBaseUrl"] : process.env.REACT_APP_API_URL;
    if (isDebug === true) {
      console.debug("Using the server base URL '" + ROUTES.API_URL + "'");
    }
    ROUTES.LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
    ROUTES.FORM_UI_URL = process.env.REACT_APP_FORM_UI_URL;

    async function renewCredentialsViaParent() {
      console.debug("Renewing the credentials via the iframe parent");
      const parentProxy = await Communication.initialize(true);
      try {
        const credentials = await parentProxy.call("onCredentials");
        console.debug(
          "Received the credentials with " +
            (credentials.apiKey !== undefined
              ? "an API key"
              : credentials.accessToken !== undefined
              ? "an access token"
              : "no API key and no access token")
        );
        return credentials;
      } finally {
        await parentProxy.destroy();
      }
    }

    async function renewAccessTokenDirectly(credentials) {
      console.debug("Renewing the OAuth access token via the user credentials");
      const accessTokenInputs = new AccessTokenInputs(credentials.email, credentials.source, credentials.refreshToken);
      const accessTokenResult = await MotionlyApiClient.userApi().accessToken(accessTokenInputs);
      credentials.accessToken = accessTokenResult.accessToken;
      const now = Date.now();
      credentials.accessTokenCreationTimestampInMilliseconds = now;
      credentials.accessTokenExpirationTimestampInMilliseconds =
        now + MotionlyApiClient.accessTokenValidityTimespanInMilliseconds;
      return credentials;
    }

    async function renewCredentials() {
      let credentials;
      if (isInIframe() === true) {
        // The form is in iframe and the refresh token is undefined
        credentials = await renewCredentialsViaParent();
      } else {
        credentials = await renewAccessTokenDirectly(getCredentials());
      }
      saveCredentials(credentials);
      return credentials.accessToken;
    }

    // noinspection JSUnusedGlobalSymbols
    await MotionlyApiClient.initialize({
      API_LAYOUT_HEADER_NAME: MotionlyApiCompanion.API_LAYOUT_HEADER_NAME,
      API_LAYOUT_HEADER_VALUE: MotionlyApiCompanion.API_LAYOUT_HEADER_VALUE,
      isDebug: () => {
        return isDebug;
      },
      getTimeoutInMilliseconds: () => {
        return 2 * 60000;
      },
      initialize: async () => {
        let credentials = getCredentials();
        if (credentials !== undefined) {
          if (isInIframe() === true) {
            credentials = await renewCredentialsViaParent();
          } else {
            const accessTokenExpirationTimestampInMilliseconds =
              credentials.accessTokenExpirationTimestampInMilliseconds;
            if (
              accessTokenExpirationTimestampInMilliseconds === undefined ||
              Date.now() >= accessTokenExpirationTimestampInMilliseconds
            ) {
              credentials = await renewAccessTokenDirectly(credentials);
            }
          }
          saveCredentials(credentials);
        }
      },
      getServerBaseUrl: () => {
        return ROUTES.API_URL;
      },
      useAccessTokenForAuthentication: () => {
        const useAccessToken = getApiKey() === undefined;
        if (isDebug === true) {
          console.debug(
            "Using the " + (useAccessToken === true ? "access token" : "API key") + " for the authentication"
          );
        }
        return useAccessToken;
      },
      getApiKey: () => {
        return getApiKey();
      },
      getAccessToken: () => {
        return getAccessToken();
      },
      ensureAccessToken: async () => {
        let credentials = getCredentials();
        const accessTokenExpirationTimestampInMilliseconds = credentials?.accessTokenExpirationTimestampInMilliseconds;
        if (
          accessTokenExpirationTimestampInMilliseconds === undefined ||
          Date.now() >= accessTokenExpirationTimestampInMilliseconds
        ) {
          return renewCredentials();
        }
        return getAccessToken();
      },
      renewAccessToken: async () => {
        return renewCredentials();
      },
    });
  },
  getTemplate: async (templateId, templateVersion, ignoreStubs = false) => {
    let template;
    if (USE_STUBS === false || ignoreStubs) {
      template = await MotionlyApiClient.templateApi().get(templateId, {
        version: templateVersion,
      });
    } else {
      const locationArray = window.location.hash.split("/");
      if (locationArray.length >= 1) {
        const templateName = locationArray[locationArray.length - 1];
        template = require("./stubs/" + templateName + ".json");
      } else {
        template = require("./stubs/basic.json");
      }
    }
    template = await TemplateService.afterFetchTemplate(template);
    return template;
  },
  getUnits: async () => {
    return MotionlyApiClient.unitApi().list();
  },
  getTemplateList: async () => {
    return MotionlyApiClient.templateApi().list();
  },
  getExperience: async experienceId => {
    return MotionlyApiClient.experienceApi().get(experienceId);
  },
  getVastUrl: async experienceId => {
    return MotionlyApiClient.experienceApi().getVastUrlByExperienceId(experienceId);
  },
  getExperienceInputs: async experienceId => {
    return MotionlyApiClient.experienceApi().getInputs(experienceId);
  },
  build: async (templateId, templateVersion, payload, experienceId) => {
    return MotionlyApiClient.templateApi().build(templateId, templateVersion, payload, {
      experienceId: experienceId,
    });
  },
  useProxy: async url => {
    return MotionlyApiClient.templateApi().proxy(url);
  },
  toCsv: async googleSpreadSheetKey => {
    return MotionlyApiClient.templateApi().toCsv(googleSpreadSheetKey);
  },
  convertFont: async (format, font) => {
    return MotionlyApiClient.assetApi().fontConvert(format, font, {});
  },
  convertVideo: async (format, video) => {
    return MotionlyApiClient.assetApi().videoConvert(format, video, {
      codec: undefined,
      quality: "low",
      bitrateInKiloBytesPerSecond: undefined,
    });
  },
  resizeVideo: async (format, video, width, height) => {
    return MotionlyApiClient.assetApi().videoResize(format, video, {
      width: width,
      height: height,
      codec: undefined,
      quality: "high",
      bitrateInKiloBytesPerSecond: undefined,
    });
  },
  declareVideo: async (video, name) => {
    return MotionlyApiClient.assetApi().videoDeclare(video, {
      name: name,
      bitrateInKiloBytesPerSecond: undefined,
    });
  },
  transcodeVideo: async video => {
    return MotionlyApiClient.assetApi().videoTranscode(video);
  },
  getExperiencePreview: async experienceId => {
    return MotionlyApiClient.experienceApi().html({
      id: experienceId,
      navigationBar: None,
      theme: LightMode,
      bodyWidthPercentage: 100,
      experienceWidthPercentage: 90,
      poweredBy: false,
      inIframe: true,
    });
  },
  setExperiencePoster: async (experienceId, posterComputation) => {
    return MotionlyApiClient.experienceApi().poster(experienceId, {
      trackId: posterComputation.trackId,
      percentage: posterComputation.percentage,
      quality: posterComputation.quality,
      backgroundColor: posterComputation.backgroundColor,
    });
  },
  uploadTemporaryFile: async (mimeType, fileName, requestBody) => {
    return MotionlyApiClient.storageApi().temporaryCreate(mimeType, fileName, requestBody);
  },
  duplicateExperience(experienceId, name, comment) {
    return MotionlyApiClient.experienceApi().duplicate(experienceId, name, {
      comment,
    });
  },
  setExperienceUnits(experienceId, technicalIds) {
    return MotionlyApiClient.experienceApi().setUnits(experienceId, {
      units: technicalIds.map(technicalId => ({ technicalId })),
    });
  },
  setDescription(experienceId, description) {
    return MotionlyApiClient.experienceApi().setDescription(experienceId, description);
  },
  renameExperience(experienceId, name) {
    return MotionlyApiClient.experienceApi().rename(experienceId, { name });
  },
  getSite(siteId) {
    return MotionlyApiClient.siteApi().get(siteId);
  },
  attachExperienceToSite(siteId, experienceId) {
    return MotionlyApiClient.siteApi().withExperience(siteId, experienceId, true);
  },
  getBinaryWeight: async ({
    experienceId,
    dimensions,
    sdkVersion,
    applicationPublicId = "callbacks",
    userAgent,
    language,
  }) => {
    return MotionlyApiClient.experienceApi().binaryWeight(experienceId, dimensions, {
      sdkVersion,
      userAgent,
      applicationPublicId,
      language,
    });
  },
  getSpritesList() {
    return MotionlyApiClient.externalSpriteApi().list();
  },
  getSpriteById(spriteId, version) {
    return MotionlyApiClient.externalSpriteApi().getOne(spriteId, { version });
  },
};
