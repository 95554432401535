import Core from "~/services/Core";
import Api from "~/util/Api";

export const syntheticUnitId = "synthetic-unit";

let UNITS;

export const AD_UNIT_OVERLAY_THUMBNAIL = "overlay_thumbnail";
export const AD_UNIT_INTERSTITIAL = "interstitial";
export const AD_UNIT_IN_ARTICLE_REVEAL = "in_article-reveal";
export const AD_UNIT_IN_ARTICLE_300X250 = "in_article-300x250";
export const AD_UNIT_IN_ARTICLE_LANDSCAPE = "in_article-landscape";
export const AD_UNIT_HEADER_AD = "header_ad";
export const AD_UNIT_FOOTER_AD = "footer_ad";
export const AD_UNIT_BANNER_970X90 = "banner-970x90";
export const AD_UNIT_BANNER_970X250 = "banner-970x250";
export const AD_UNIT_BANNER_728X90 = "banner-728x90";
export const AD_UNIT_BANNER_640X360 = "banner-640x360";
export const AD_UNIT_BANNER_336X280 = "banner-336x280";
export const AD_UNIT_BANNER_320X50 = "banner-320x50";
export const AD_UNIT_BANNER_320X480 = "banner-320x480";
export const AD_UNIT_BANNER_320X100 = "banner-320x100";
export const AD_UNIT_BANNER_300X600 = "banner-300x600";
export const AD_UNIT_BANNER_300X50 = "banner-300x50";
export const AD_UNIT_BANNER_300X250 = "banner-300x250";
export const AD_UNIT_BANNER_160X600 = "banner-160x600";
export const AD_UNIT_BANNER_300X100 = "banner-300x100";

const createBannerConfiguration = (creativeRatio, width, height) => ({
  ad_unit_type: "standard_banners",
  creative_ratio: creativeRatio,
  width,
  height,
});

const getOguryPlayerConfiguration = ({ creativeRatio, containerWidth, containerHeight }) => {
  const commonInArticleProperties = {
    width: 375,
    height: 100,
    ad_unit_type: "in_article",
    creative_ratio: creativeRatio,
  };

  return {
    [AD_UNIT_INTERSTITIAL]: {
      width: containerWidth,
      height: containerHeight,
      ad_unit_type: "overlay_thumnbail",
    },
    [AD_UNIT_OVERLAY_THUMBNAIL]: {
      width: 180,
      height: 135,
      ad_unit_type: "overlay_thumnbail",
    },
    [AD_UNIT_FOOTER_AD]: {
      width: 101,
      height: 180,
      ad_unit_type: "footer_ad",
    },
    [AD_UNIT_HEADER_AD]: {
      width: 375,
      height: 100,
      ad_unit_type: "header_ad",
    },
    [AD_UNIT_IN_ARTICLE_REVEAL]: {
      ...commonInArticleProperties,
      in_article_type: "reveal",
    },
    [AD_UNIT_IN_ARTICLE_LANDSCAPE]: {
      ...commonInArticleProperties,
      in_article_type: "landscape",
    },
    [AD_UNIT_IN_ARTICLE_300X250]: {
      ...commonInArticleProperties,
      in_article_type: "300x250",
    },
    [AD_UNIT_BANNER_970X90]: createBannerConfiguration(creativeRatio, 970, 90),
    [AD_UNIT_BANNER_970X250]: createBannerConfiguration(creativeRatio, 970, 250),
    [AD_UNIT_BANNER_728X90]: createBannerConfiguration(creativeRatio, 728, 90),
    [AD_UNIT_BANNER_640X360]: createBannerConfiguration(creativeRatio, 640, 360),
    [AD_UNIT_BANNER_336X280]: createBannerConfiguration(creativeRatio, 336, 280),
    [AD_UNIT_BANNER_320X50]: createBannerConfiguration(creativeRatio, 320, 50),
    [AD_UNIT_BANNER_320X480]: createBannerConfiguration(creativeRatio, 320, 480),
    [AD_UNIT_BANNER_320X100]: createBannerConfiguration(creativeRatio, 320, 100),
    [AD_UNIT_BANNER_300X600]: createBannerConfiguration(creativeRatio, 300, 600),
    [AD_UNIT_BANNER_300X50]: createBannerConfiguration(creativeRatio, 300, 50),
    [AD_UNIT_BANNER_300X250]: createBannerConfiguration(creativeRatio, 300, 250),
    [AD_UNIT_BANNER_160X600]: createBannerConfiguration(creativeRatio, 160, 600),
    [AD_UNIT_BANNER_300X100]: createBannerConfiguration(creativeRatio, 300, 100),
  };
};

const templateConfig = {
  brandspotlight: {
    technicalIds: [AD_UNIT_INTERSTITIAL],
  },
  interactivepuzzle: {
    technicalIds: [AD_UNIT_INTERSTITIAL],
  },
  "coresurvey-mpu": {
    technicalIds: [AD_UNIT_IN_ARTICLE_300X250, AD_UNIT_BANNER_300X250],
    hidden: true,
  },
  coresurvey: {
    technicalIds: [AD_UNIT_INTERSTITIAL, AD_UNIT_IN_ARTICLE_REVEAL, AD_UNIT_BANNER_300X600, AD_UNIT_BANNER_320X480],
    hidden: true,
  },
};

async function initialize() {
  if (!UNITS) {
    UNITS = await Api.getUnits();
  }
}

function getUnits() {
  return UNITS;
}

async function getUnitsTemplateDefinition(templateId) {
  const units = getUnits();
  const config = templateConfig[templateId] || {};

  const acceptableValues = units
    .filter(unit => {
      return config.technicalIds ? config.technicalIds.includes(unit.technicalId) : true;
    })
    .map(unit => ({
      value: unit.technicalId,
      label: unit.name,
    }));

  const defaultValue = acceptableValues[0].value;

  return {
    id: syntheticUnitId,
    type: "string",
    ui: {
      name: "Ad Unit",
      label: "Ad Unit",
    },
    semantics: {
      kind: "synthetic",
    },
    acceptableValues,
    style: {
      layout: "combo",
      orientation: "horizontal",
    },
    default: defaultValue,
    ...(config.hidden ? { conditions: { hidden: true } } : {}),
  };
}

function getAvailableRatios(unitTechnicalId) {
  const units = getUnits();
  const ratiosPerUnit = units
    ?.find(unit => unit.technicalId === unitTechnicalId)
    ?.properties.map(property => ({ value: property.ratio, isDefault: property.isDefault }));
  return ratiosPerUnit || [];
}

function getCurrentUnitInputInstance() {
  return Core.getDeepNodeById("root." + syntheticUnitId, true);
}

function getCurrentUnitTechnicalId() {
  return getCurrentUnitInputInstance()?.getValue();
}

function filterRatioOptions(options) {
  const currentUnitTechnicalId = getCurrentUnitTechnicalId();

  if (currentUnitTechnicalId) {
    const ratiosAvailables = getAvailableRatios(currentUnitTechnicalId);
    const matchedOptions = [];

    const defaultRatio = ratiosAvailables.find(ratio => ratio.isDefault);
    const defaultOption = options.find(option => option.value === defaultRatio?.value);
    if (defaultRatio && defaultOption) {
      matchedOptions.push(defaultOption);
    }
    ratiosAvailables
      .filter(ratio => !ratio.isDefault)
      .forEach(ratio => {
        const matchedOption = options.find(option => option.value === ratio.value);
        if (matchedOption) {
          matchedOptions.push(matchedOption);
        }
      });
    return matchedOptions;
  }
  return options;
}

function getUnitsForSpecificRatio(ratio) {
  const units = getUnits();
  return units.filter(unit => unit.properties.some(property => property.ratio === ratio));
}

function getDangerIconSizeByAdUnitName(adUnitName) {
  if (adUnitName.startsWith("banner")) {
    return 0;
  }
  // Keep this function to add some specificities to a potential adUnit
  return 48;
}

function isInArticle(adUnitTechnicalId) {
  return adUnitTechnicalId?.startsWith("in_article");
}
function isThumbnail(adUnitTechnicalId) {
  return adUnitTechnicalId === "overlay_thumbnail";
}

function isBanner(adUnitTechnicalId) {
  return adUnitTechnicalId?.startsWith("banner-");
}

async function getDangerZonesProperties(ratio) {
  const currentUnitTechnicalId = getCurrentUnitTechnicalId();

  if (currentUnitTechnicalId) {
    const units = getUnits();
    const unitProperty = units
      ?.find(unit => unit.technicalId === currentUnitTechnicalId)
      ?.properties?.find(property => property.ratio === ratio);
    if (unitProperty) {
      switch (unitProperty.containerPolicy) {
        case "fitHeight":
          return {
            direction: "vertical",
            size: (unitProperty.dangerZonesSizePercentage || 0) + "%",
          };
        default:
          return undefined;
      }
    }
  }
  return undefined;
}

export default {
  initialize,
  filterRatioOptions,
  getDangerZonesProperties,
  getDangerIconSizeByAdUnitName,
  getUnitsTemplateDefinition,
  getCurrentUnitTechnicalId,
  getCurrentUnitInputInstance,
  getUnitsForSpecificRatio,
  getOguryPlayerConfiguration,
  getUnits,
  isInArticle,
  isThumbnail,
  isBanner,
};
