import { InputWrapper } from "components";
import FilePickerV2 from "../../../components/FilePickerV2/FilePickerV2";
import Api from "~/util/Api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageDimensionsFromUrl } from "~/util/Utils";

const IMAGES_LIMIT_SIZE = 2;

const InputMultipleImagesUi = ({ input }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    if (input.getValue() && input.getValue().length) {
      const uploadedFiles = input.value.map((image, index) => ({
        file: { uid: index, name: image.image, type: image["image-meta"].mimeType },
        name: image.image,
        status: "done",
        url: image.image,
        width: image["image-meta"].width,
        height: image["image-meta"].height,
      }));
      setUploadedFiles(uploadedFiles);
    }
  }, []);

  function updateFileState(fileUid, updates) {
    setUploadedFiles(prevState =>
      prevState
        .map(uploadedFile => (uploadedFile.file.uid === fileUid ? { ...uploadedFile, ...updates } : uploadedFile))
        .sort((a, b) => a.file.name.localeCompare(b.file.name))
    );
  }

  async function processFile(file) {
    const { uid, name, type } = file;
    setUploadedFiles(prevState => [...prevState, { file, name, status: "uploading", percent: 0 }]);

    async function validateFile(file) {
      const errors = [];
      if (file.size > IMAGES_LIMIT_SIZE * 1024 * 1024) {
        errors.push(t("error.constraint.imagesWeight", { maximumMegaBytes: IMAGES_LIMIT_SIZE }));
      }
      return errors;
    }

    const fileErrors = await validateFile(file);

    if (fileErrors.length > 0) {
      //TODO set an error message
      updateFileState(uid, { percent: 100, status: "error" });
      return;
    }

    input.resetErrors();

    try {
      const { url } = await Api.uploadTemporaryFile(type, encodeURIComponent(name), file);
      const dimensions = await getImageDimensionsFromUrl(url);
      updateFileState(uid, { percent: 100, status: "done", url, width: dimensions.width, height: dimensions.height });
    } catch (error) {
      //TODO set an error message
      updateFileState(uid, { percent: 100, status: "error" });
      console.warn(error);
    }
  }

  useEffect(() => {
    if (uploadedFiles.length) {
      const inputValue = uploadedFiles
        .filter(uploadedFile => uploadedFile.status === "done")
        .map(uploadedFile => ({
          image: uploadedFile.url,
          "image-meta": {
            mimeType: uploadedFile.file.type,
            width: uploadedFile.width,
            height: uploadedFile.height,
            ratio: uploadedFile.width / uploadedFile.height,
          },
        }));
      input.setValue(inputValue);
    }
  }, [uploadedFiles]);

  function handleOnRemove(file) {
    setUploadedFiles(prevState => prevState.filter(uploadedFile => uploadedFile.file.uid !== file.file.uid));
  }

  return (
    <InputWrapper input={input}>
      <FilePickerV2
        multiple
        hint={t("components.filePicker.uploadHintImages")}
        beforeUpload={processFile}
        action={() => "false"}
        accept={[".jpeg", ".jpg", ".gif", ".png", ".webp"]}
        showUploadList={{ showDownloadIcon: true }}
        maxCount={1}
        listType="picture"
        onRemove={handleOnRemove}
        fileList={uploadedFiles}
      />
    </InputWrapper>
  );
};
export default InputMultipleImagesUi;
