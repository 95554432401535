import jsonMap from "json-source-map";
import Api from "~/util/Api";
import DescriptionDefinitions from "~/util/DescriptionDefinitions";

const actions = {
  pointerSeparator: "/",
  defaultAnimations: [
    {
      from: {
        percentage: 0,
        state: 0,
      },
      to: {
        percentage: 100,
        state: 0,
      },
    },
  ],
  defaultStates: [
    {
      x: 50,
      xAlign: "middle",
      y: 50,
      yAlign: "middle",
      size: 100,
      color: "#000000FF",
    },
  ],
};

export function getAssets(description, includeDisabled = true) {
  if (!description) return [];

  const assets = [];

  const addAsset = (type, url) => {
    const name = url.split("/").pop();

    const exists = assets.some(asset => asset.name === name && asset.url === url);

    if (!exists) {
      assets.push({ type, url, name });
    }
  };

  description.tracks?.forEach(track => {
    track.layers?.forEach(layer => {
      if (!Array.isArray(layer.sprites) || layer.enabled === false) return;

      layer.sprites.forEach(sprite => {
        if ((includeDisabled || sprite.enabled !== false) && sprite.url) {
          addAsset("sprite", sprite.url);
        }
      });
    });
  });

  description.externalAssets?.forEach(asset => {
    if (asset.url) {
      addAsset(asset.type, asset.url);
    }
  });

  return assets;
}

export function computeSprites(description, includeDisabled) {
  const sprites = [];
  if (description.tracks !== undefined) {
    for (const track of description.tracks) {
      if (track.layers !== undefined) {
        for (const layer of track.layers) {
          if (layer.sprites !== undefined) {
            for (const sprite of layer.sprites) {
              if (sprite.enabled === true || includeDisabled === true) {
                sprites.push(sprite);
              }
            }
          }
        }
      }
    }
  }
  return sprites;
}

export function computeDescriptionEntityFromPosition(pointers, description, lineNumber, column) {
  let inTracks = false;
  let track;
  let trackIndex;
  let inLayers = false;
  let layer;
  let layerIndex;
  let inParticles = false;
  let particle;
  let particleType;
  let particleIndex;
  if (pointers !== undefined && description !== undefined) {
    const line = lineNumber - 1;
    const relevantPointers = [];
    Object.keys(pointers).forEach((key, index) => {
      const pointer = pointers[key];
      let isRelevant = false;
      if (pointer.key !== undefined) {
        if (
          line >= pointer.key.line &&
          line <= pointer.keyEnd.line &&
          (line !== pointer.key.line || column >= pointer.key.column) &&
          (line !== pointer.keyEnd.line || column <= pointer.keyEnd.column)
        ) {
          isRelevant = true;
        }
      }
      if (
        line >= pointer.value.line &&
        line <= pointer.valueEnd.line &&
        (line !== pointer.value.line || column >= pointer.value.column) &&
        (line !== pointer.valueEnd.line || column <= pointer.valueEnd.column)
      ) {
        isRelevant = true;
      }
      if (isRelevant === true) {
        relevantPointers.push({ key: key, pointer: pointer });
      }
    });

    if (relevantPointers.length >= 1) {
      const { key, pointer } = relevantPointers.pop();
      const tokens = key.split(actions.pointerSeparator).slice(1);
      let index = 0;
      if (index < tokens.length && tokens[index] === DescriptionDefinitions.TRACKS) {
        index++;
        inTracks = true;
        trackIndex = parseInt(tokens[index++]);
        track = index < tokens.length ? description[DescriptionDefinitions.TRACKS][trackIndex] : undefined;
      }
      if (index < tokens.length && tokens[index] === DescriptionDefinitions.LAYERS) {
        index++;
        inLayers = true;
        layerIndex = parseInt(tokens[index++]);
        layer = index < tokens.length ? track[DescriptionDefinitions.LAYERS][layerIndex] : undefined;
      }
      if (
        index < tokens.length &&
        (tokens[index] === DescriptionDefinitions.SPRITES ||
          tokens[index] === DescriptionDefinitions.TEXTS ||
          tokens[index] === DescriptionDefinitions.SHAPES)
      ) {
        particleType = tokens[index];
        index++;
        inParticles = true;
        particleIndex = parseInt(tokens[index++]);
        particle = index < tokens.length ? layer[particleType][particleIndex] : undefined;
      }
    }
  }
  const entity = {
    inTracks: inTracks,
    track: track,
    trackIndex: trackIndex,
    inLayers: inLayers,
    layer: layer,
    layerIndex: layerIndex,
    inParticles: inParticles,
    particle: particle,
    particleType: particleType,
    particleIndex: particleIndex,
  };
  return entity;
}

export function computePointers(string) {
  return jsonMap.parse(string).pointers;
}

export function selectLayer(description, particleType, options) {
  const trackIndex = options !== undefined ? options.trackIndex : undefined;
  const layerIndex = options !== undefined ? options.layerIndex : undefined;
  const track = trackIndex !== undefined ? description.tracks[trackIndex] : undefined;
  const layer = track !== undefined && layerIndex !== undefined ? track.layers[layerIndex] : undefined;
  if (layer !== undefined) {
    if (layer[particleType] === undefined) {
      layer[particleType] = [];
    }
    return { description: description, particles: layer[particleType] };
  }
}

function createExternalSprite(
  dynamicSpriteFileName,
  name,
  useAssetNameForJavaScript,
  parameters,
  spriteKind,
  assetType,
  externalSpriteUrl
) {
  return {
    spriteKind: spriteKind,
    assetType: assetType,
    fileName: useAssetNameForJavaScript === true ? name + ".js" : dynamicSpriteFileName,
    parameters: parameters,
    externalSpriteUrl: externalSpriteUrl,
  };
}

export async function createLottieExternalSprite(name, useAssetNameForJavaScript, mode) {
  const parameters = { mode };

  try {
    const res = await Api.getSpritesList();
    const lottieItem = res.find(item => item.technicalId === "lottie");
    const lastVersion = Math.max(...lottieItem.versions);
    const lottieRes = await Api.getSpriteById("lottie", lastVersion);
    return createExternalSprite(
      "lottie.js",
      name,
      useAssetNameForJavaScript,
      parameters,
      DescriptionDefinitions.SpriteKinds.Dynamic,
      DescriptionDefinitions.ExternalAssetTypes.Animation,
      lottieRes.url
    );
  } catch (err) {
    return null;
  }
}

export async function addLottieSprite(description, lottieAssetUrl, options) {
  const { name, layerIndex, trackIndex, mode } = options;
  const spriteResult = await createLottieExternalSprite(name, false, mode);
  const layerResult = selectLayer(description, DescriptionDefinitions.SpriteParticles, {
    layerIndex,
    trackIndex,
  });
  const { description: newDescription, particles } = layerResult;
  const { fileName: javaScriptFileName, assetType, spriteKind, parameters, externalSpriteUrl } = spriteResult;
  const assets = [];
  assets.push({
    id: name,
    type: assetType,
    url: lottieAssetUrl,
  });

  newDescription[DescriptionDefinitions.EXTERNAL_ASSETS] = newDescription[DescriptionDefinitions.EXTERNAL_ASSETS] || [];
  const externalAssets = newDescription[DescriptionDefinitions.EXTERNAL_ASSETS];
  for (const asset of assets) {
    if (
      externalAssets.find(externalAsset => {
        return externalAsset.url === asset.url;
      }) === undefined
    ) {
      externalAssets.push(asset);
    }
  }
  let spriteId = name;
  let spriteIdIndex = 1;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const descriptionSprites = computeSprites(newDescription, true);
    if (
      descriptionSprites.find(descriptionSprite => {
        return descriptionSprite.id === spriteId;
      }) === undefined
    ) {
      break;
    }
    spriteId = name + "-" + spriteIdIndex++;
  }

  const particle = {
    id: spriteId,
    order: 1,
    kind: spriteKind,
    externals: {
      assetIds: assets.map(asset => {
        return asset.id;
      }),
      parameters: Object.keys(parameters).length <= 0 ? undefined : parameters,
    },
    url: externalSpriteUrl !== undefined ? externalSpriteUrl : "file://" + javaScriptFileName,
    sizeComputation: DescriptionDefinitions.Orientations.Horizontal,
    animations: [
      {
        from: {
          percentage: 0,
          state: 0,
        },
        to: {
          percentage: 100,
          state: 0,
        },
        motionRange: spriteKind === DescriptionDefinitions.SpriteKinds.Free ? undefined : [0, 100],
      },
    ],
    states: [
      {
        x: 50,
        xAlign: "middle",
        y: 50,
        yAlign: "middle",
        size: 100,
      },
    ],
  };
  particles.push(particle);
  console.log("NEW DESCRIPTION", newDescription);
  return { description: newDescription, particle: particle };
}

export function computeTrackIds(description) {
  if (description.tracks === undefined) {
    return [];
  }
  return description.tracks.map(track => {
    return track.id;
  });
}

export function addTrack(description, options) {
  const trackIndex = options !== undefined ? options.index : undefined;
  const trackId = options.trackId;
  if (trackId === undefined) {
    return undefined;
  }
  const track = { id: trackId, layers: [] };
  description.tracks.splice(trackIndex === undefined ? description.tracks.length : trackIndex, 0, track);
  return { description: description, track: track };
}

export function addLayer(description, options) {
  const trackIndex = options !== undefined ? options.trackIndex : undefined;
  const layerIndex = options !== undefined ? options.index : undefined;

  const trackId = description.tracks[trackIndex]?.id;
  if (trackId === undefined) {
    return undefined;
  }
  const track = description.tracks.find(track => {
    return track.id === trackId;
  });
  const depths = track.layers.map(layer => {
    return layer.depth;
  });
  let depth = 0;
  while (depths.indexOf(depth) !== -1) {
    depth++;
  }
  const layer = { depth: depth };
  track.layers.splice(layerIndex === undefined ? track.layers.length : layerIndex, 0, layer);
  return { description: description, layer: layer };
}

export function addImageSprite(description, options, imageUrl) {
  const particleIndex = options !== undefined ? options.index : undefined;
  const format = imageUrl.split(".").pop().toUpperCase() === "PNG" ? "PNG" : "JPEG";
  const result = selectLayer(description, DescriptionDefinitions.SpriteParticles, options);
  const { description: newDescription, particles } = result;
  const particle = {
    order: 1,
    url: imageUrl,
    format: format,
    quality: format !== undefined && format !== "PNG" && format !== "GIF" ? 90 : undefined,
    sizeComputation: DescriptionDefinitions.Orientations.Horizontal,
    animations: actions.defaultAnimations,
    states: actions.defaultStates,
  };
  particles.splice(particleIndex === undefined ? particles.length : particleIndex, 0, particle);
  return { description: newDescription, particle: particle };
}
