import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputHeader } from "..";
import localStyle from "./InputWrapper.module.scss";

const InputWrapper = ({ input, children }) => {
  const [showDetached, setShowDetached] = useState(false);

  function onDetach() {
    setShowDetached(true);
  }

  function onAttach() {
    setShowDetached(false);
  }

  function onEscapeDetached(event) {
    if (event.key === "Escape") {
      onAttach();
    }
  }

  useEffect(() => {
    if (showDetached) {
      document.addEventListener("keydown", onEscapeDetached, false);
    } else {
      document.removeEventListener("keydown", onEscapeDetached, false);
    }
    return () => document.removeEventListener("keydown", onEscapeDetached, false);
  }, [showDetached]);

  return (
    <div>
      <InputHeader input={input} canBeDetached={input.canBeDetached() === true} onDetach={onDetach} />

      <div className={showDetached ? localStyle.detachedContainer : {}}>
        <div className={localStyle.container}>
          {/*{showDetached === true && (
            <div className={localStyle.detachedHeader}>
              <Button type="tertiary" size="small" onClick={onAttach} style={{ marginBottom: 10 }}>
                <Icons name={IconNames.CompressOutlined.type} />
              </Button>
            </div>
          )}*/}
          <div className={localStyle.childrenContainer}>{input.isEnabled() === true && children}</div>
        </div>
      </div>
    </div>
  );
};

InputWrapper.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default InputWrapper;
