export function getAssets(description, includeDisabled = true) {
  if (!description) return [];

  const assets = [];

  const addAsset = (type, url) => {
    const name = url.split("/").pop();

    const exists = assets.some(asset => asset.name === name && asset.url === url);

    if (!exists) {
      assets.push({ type, url, name });
    }
  };

  description.tracks?.forEach(track => {
    track.layers?.forEach(layer => {
      if (!Array.isArray(layer.sprites) || layer.enabled === false) return;

      layer.sprites.forEach(sprite => {
        if ((includeDisabled || sprite.enabled !== false) && sprite.url) {
          addAsset("sprite", sprite.url);
        }
      });
    });
  });

  description.externalAssets?.forEach(asset => {
    if (asset.url) {
      addAsset(asset.type, asset.url);
    }
  });

  return assets;
}
