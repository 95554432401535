import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Editor from "@monaco-editor/react";
import AttachIcon from "remixicon-react/Attachment2Icon";
import {
  Button,
  Modal,
  Dropdown,
  Typography,
  ModalClosableFooter,
  Select,
  useAppNotification,
  Row,
  Col,
  theme,
} from "@ogury/design-system";

import { TextInput, FileInput } from "components";
import Api from "~/util/Api";
import { useExperience } from "~/context";
import { NOTIFICATION_PLACEMENT } from "~/util/Constant";
import {
  computeDescriptionEntityFromPosition,
  computePointers,
  addTrack,
  addImageSprite,
  computeTrackIds,
  addLottieSprite,
  addLayer,
} from "~/util/ExperienceDescription";

import style from "./CodeEditor.module.scss";
import schema from "./schema.json";

const availableActionsList = [
  { key: "addLottie", label: "Add Lottie", inLayers: true },
  { key: "addImage", label: "Add Image", inLayers: true },
  { key: "addTrack", label: "Add Track", inLayers: false },
  { key: "addLayer", label: "Add Layer", inTracks: true, layerIndex: undefined },
];

const lottieModes = [
  {
    value: "pause",
    label: "Pause: pauses the Lottie when the animation pauses",
  },
  {
    value: "always",
    label: "Always: keeps on running the Lottie even when the animation pauses and forces the animation to render",
  },
  {
    value: "alwaysLoop",
    label:
      "Always: keeps on running the Lottie even when the animation pauses, loops and forces the animation to render",
  },
];

export default function CodeEditor() {
  const { experience, setExperience } = useExperience();
  const [t] = useTranslation();
  const notification = useAppNotification();

  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeAction, setActiveAction] = useState(null);
  const [activeEntity, setActiveEntity] = useState(null);
  const [availableActions, setAvailableActions] = useState([]);
  const [newTrackId, setNewTrackId] = useState("");
  const [modalSourceUrl, setModalSourceUrl] = useState("");
  const [lottieMode, setLottieMode] = useState(lottieModes[0].value);
  const [lottieSpriteId, setLottieSpriteId] = useState("");
  const editorCode = useRef(code);

  function formatJSON(val = {}) {
    try {
      return JSON.stringify(val, null, 2);
    } catch {
      const errorJson = {
        error: `Error formatting the code${val}`,
      };
      return JSON.stringify(errorJson, null, 2);
    }
  }

  useEffect(() => {
    if (code === "" && experience.description) {
      const formattedCode = formatJSON(experience.description);
      setCode(formattedCode);
      editorCode.current = formattedCode;
    }
  }, [experience]);

  function onValidate(markers) {
    if (markers.length > 0) {
      setExperience({ ...experience, dirty: false });
    }
  }

  function onCodeChange(value) {
    setCode(value);
    try {
      const newJSON = JSON.parse(value);
      setExperience({ ...experience, description: newJSON, dirty: true });
      editorCode.current = value;
    } catch (error) {
      console.warn("Not valid JSON", error);
    }
  }

  function onCursorPositionChange(event) {
    if (!editorCode.current) {
      return;
    }
    const { lineNumber, column } = event.position;
    const pointers = computePointers(editorCode.current);
    const entity = computeDescriptionEntityFromPosition(pointers, JSON.parse(editorCode.current), lineNumber, column);
    setActiveEntity(entity);
    setAvailableActions(
      availableActionsList.filter(action => {
        if (action.key === "addLayer") {
          return (
            action.inTracks === entity.inTracks && action.layerIndex === entity.layerIndex && entity.trackIndex >= 0
          );
        } else {
          return action.inLayers === entity.inLayers;
        }
      })
    );
  }

  function setActiveEditorAction(action) {
    setActiveAction(action);
    if (action.key === "addTrack") {
      const tracksLength = computeTrackIds(experience.description)?.length;
      const newTrackIdPlaceholder = `track_${tracksLength + 1}`;
      setNewTrackId(newTrackIdPlaceholder);
    }
  }

  function getAvailableActions() {
    return availableActions.map(action => ({
      label: action.label,
      key: action.key,
      onClick: () => setActiveEditorAction(action),
    }));
  }

  async function onModalSubmit() {
    const { trackIndex, layerIndex } = activeEntity;
    try {
      let result;
      setIsLoading(true);
      if (activeAction.key === "addTrack") {
        result = addTrack(experience.description, { trackIndex, trackId: newTrackId });
      } else if (activeAction.key === "addImage") {
        result = addImageSprite(experience.description, { trackIndex, layerIndex }, modalSourceUrl);
      } else if (activeAction.key === "addLayer") {
        result = addLayer(experience.description, { trackIndex, layerIndex });
      } else if (activeAction.key === "addLottie") {
        result = await addLottieSprite(experience.description, modalSourceUrl, {
          trackIndex,
          layerIndex,
          name: lottieSpriteId,
          mode: lottieMode,
        });
      }
      onCodeChange(formatJSON(result.description));
      setActiveAction(null);
      setModalSourceUrl("");
      setLottieSpriteId("");
      notification.success({
        message: "Successfully added the entity",
        description: "Success",
        placement: NOTIFICATION_PLACEMENT,
      });
    } catch (error) {
      notification.error({
        message: error.message,
        description: "Failed to add the entity",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function processFile(file) {
    const { name, type } = file;
    const processedName = name.replace(/\s+/g, "_");

    try {
      setIsLoading(true);
      const { url } = await Api.uploadTemporaryFile(type, encodeURIComponent(processedName), file);
      setModalSourceUrl(url);
    } catch (error) {
      notification.error({
        message: error.message,
        description: "Failed to upload the file",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      setIsLoading(false);
    }
  }

  function renderFileUpload(type) {
    return (
      <FileInput
        inputId="import"
        isButton
        fileExtensions={type === "image" ? [".jpeg", ".jpg", ".png"] : [".lottie"]}
        readDataUrl={false}
        disabled={isLoading === true}
        onError={error => {
          notification.error({
            message: "An unexpected error occurred. Reason: '" + error.message + "'",
            description: "Import failed",
            placement: NOTIFICATION_PLACEMENT,
            duration: 0,
          });
          setIsLoading(false);
        }}
        onChanged={({ blob }) => {
          processFile(blob);
        }}
      >
        <AttachIcon />
      </FileInput>
    );
  }

  function renderModalContent() {
    if (!activeAction) {
      return null;
    }
    if (activeAction.key === "addTrack") {
      return (
        <>
          <Typography.P2Regular className={style.inputName}>Track identifier</Typography.P2Regular>
          <TextInput placeholder="Track ID" onChange={setNewTrackId} value={newTrackId} />
        </>
      );
    } else if (activeAction.key === "addImage") {
      return (
        <>
          <Typography.P2Regular className={style.inputName}>Image URL</Typography.P2Regular>
          <Row gutter={[parseInt(theme.spacing.space_xs)]} align="middle">
            <Col flex="auto">
              <TextInput
                placeholder="Put the image url from assets or upload new"
                onChange={setModalSourceUrl}
                value={modalSourceUrl}
              />
            </Col>
            <Col>{renderFileUpload("image")}</Col>
          </Row>
        </>
      );
    } else if (activeAction.key === "addLayer") {
      return <Typography.P2Regular>Add new Layer?</Typography.P2Regular>;
    } else if (activeAction.key === "addLottie") {
      return (
        <>
          <Typography.P2Regular className={style.inputName}>Lottie URL</Typography.P2Regular>
          <Row gutter={[parseInt(theme.spacing.space_xs)]} align="middle">
            <Col flex="auto">
              <TextInput placeholder="Lottie asset URL" onChange={setModalSourceUrl} value={modalSourceUrl} />
            </Col>
            <Col>{renderFileUpload("lottie")}</Col>
          </Row>
          <Typography.P2Regular className={style.inputName}>Name</Typography.P2Regular>
          <TextInput placeholder="The name of the sprite" onChange={setLottieSpriteId} value={lottieSpriteId} />
          <Typography.P2Regular className={style.inputName}>Mode</Typography.P2Regular>
          <Select
            options={lottieModes}
            value={lottieMode}
            onChange={setLottieMode}
            popupMatchSelectWidth={false}
            width="100%"
          />
        </>
      );
    }
  }

  function renderCode() {
    return (
      <Editor
        height="90vh"
        defaultLanguage="json"
        value={code}
        onChange={onCodeChange}
        onValidate={onValidate}
        onMount={(editor, monaco) => {
          editor.updateOptions({
            automaticLayout: true,
            contextmenu: true,
            lineNumbers: "on",
            scrollBeyondLastLine: false,
            autoIndent: true,
            showFoldingControls: "always",
            foldingHighlight: false,
          });
          monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
            validate: true,
            allowComments: true,
            schemas: [
              {
                fileMatch: ["*"],
                schema: schema,
              },
            ],
          });
          editor.onDidChangeCursorPosition(onCursorPositionChange);
        }}
      />
    );
  }

  function handleModalCancel() {
    setActiveAction(null);
    setModalSourceUrl("");
    setLottieSpriteId("");
  }

  return (
    <div className={style.codeEditorContainer}>
      <div className={style.actionButtonContainer}>
        <Dropdown menu={{ items: getAvailableActions() }} trigger={["click"]} placement="bottomRight">
          <Button>+</Button>
        </Dropdown>
      </div>
      <Modal
        footer={
          <ModalClosableFooter
            buttonLabel={t("components.OkCancelButtons.cancel")}
            actions={
              <Button loading={isLoading} onClick={onModalSubmit}>
                {t("inputsUI.button.save")}
              </Button>
            }
          />
        }
        open={!!activeAction}
        title={activeAction?.label}
        onCancel={handleModalCancel}
      >
        {renderModalContent()}
      </Modal>
      {renderCode()}
    </div>
  );
}
