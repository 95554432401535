import React, { useContext, useEffect, useState } from "react";
import { Loader, Divider, Image } from "components";
import { AccessibilityContext, Side } from "context/AccessibilityContext";
import { Tabs, Upload, Col, Row, Typography, TextOverflow, Button, theme, Modal } from "@ogury/design-system";
import CopyIcon from "remixicon-react/FileCopy2LineIcon";
import DeleteIcon from "remixicon-react/DeleteBinLineIcon";
import CheckIcon from "remixicon-react/CheckLineIcon";
import FileLineIcon from "remixicon-react/FileLineIcon";
import { useExperience } from "~/context";
import { useTranslation } from "react-i18next";
import { getAssets } from "~/util/ExperienceDescription";
import Api from "~/util/Api";
import style from "./FileManager.module.scss";

const TABS = {
  FILES: "files",
};

const createFileFromAsset = asset => ({
  ...asset,
  uid: asset.url,
  status: "done",
  canRemove: false,
});

const findExistingFiles = (prevList, filesListFromAssets) =>
  prevList.filter(existingFile => filesListFromAssets.some(newFile => newFile.uid === existingFile.uid));

const findNewFiles = (filesListFromAssets, existingFiles) =>
  filesListFromAssets.filter(newFile => !existingFiles.some(existingFile => existingFile.uid === newFile.uid));

const mergeFilesList = (prevList, filesListFromAssets) => {
  const existingFiles = findExistingFiles(prevList, filesListFromAssets);
  const newFiles = findNewFiles(filesListFromAssets, existingFiles);
  return [...existingFiles, ...newFiles];
};

export default function FileManager() {
  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(TABS.LAYERS);
  const [filesList, setFilesList] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [copiedItem, setCopiedItem] = useState(null);
  const { experience } = useExperience();
  const [imagePreviewURL, setImagePreviewURL] = useState(null);
  const [accessibilityState] = useContext(AccessibilityContext);

  const isLoading =
    accessibilityState.loading.active === true && accessibilityState.loading.sides.includes(Side.Builder);

  useEffect(() => {
    if (!experience.description || experience.dirty) {
      return;
    }
    const assets = getAssets(experience.description);
    const filesListFromAssets = assets.map(createFileFromAsset);
    setFilesList(prevList => mergeFilesList(prevList, filesListFromAssets));
  }, [experience]);

  function updateFileState(fileUid, updates) {
    setFilesList(prevState => {
      const fileExists = prevState.some(file => file.uid === fileUid);

      if (fileExists) {
        return prevState.map(file => (file.uid === fileUid ? { ...file, ...updates } : file));
      } else {
        return [...prevState, { uid: fileUid, ...updates }];
      }
    });
  }

  function removeFileByUid(fileUid) {
    setFilesList(prevState => prevState.filter(file => file.uid !== fileUid));
  }

  async function processFile(file) {
    const { uid, name, type, size } = file;
    const processedName = name.replace(/\s+/g, "_");

    try {
      setIsFileLoading(true);
      updateFileState(uid, { status: "processing", message: "Uploading", name: processedName });
      const { url } = await Api.uploadTemporaryFile(type, encodeURIComponent(processedName), file);
      updateFileState(uid, { status: "done", name: processedName, size, url, canRemove: true });
    } catch (error) {
      updateFileState(uid, { status: "error", canRemove: true });
      console.warn(error);
    } finally {
      setIsFileLoading(false);
    }
  }

  function copyToClipboard(id, url) {
    navigator.clipboard.writeText(url);
    setCopiedItem(id);
    setTimeout(() => setCopiedItem(null), 1000);
  }

  function isImageAsset(asset) {
    if (!asset?.url) return false;
    const url = asset.url.toLowerCase();
    return url.includes(".jpg") || url.includes(".jpeg") || url.includes(".png");
  }

  function renderFileTree() {
    return (
      <div className={style.container}>
        <Upload.Dragger
          data-testid="file-uploader"
          beforeUpload={processFile}
          accept={[".jpeg", ".jpg", ".png", ".lottie", ".json", ".mp4", ".js"]}
          action={() => "false"}
          showUploadList={false}
          customRequest={() => {}}
        />
        {isFileLoading && <Loader overlay />}
        <Divider />
        {filesList.map(file => (
          <Row key={file.uid} gutter={[parseInt(theme.spacing.space_xs)]} align="middle" justify="start">
            <Col>
              <Button
                className={style.fileIcon}
                data-testid="file-icon"
                onClick={() => {
                  if (isImageAsset(file)) {
                    setImagePreviewURL(file.url);
                  }
                }}
                type="tertiary"
                iconPosition="iconOnly"
                icon={isImageAsset(file) ? <Image src={file.url} /> : <FileLineIcon />}
              />
            </Col>
            <Col flex="auto">
              <Typography.P2Regular
                color={file.status === "error" ? theme.colors.destructive.content : theme.colors.primary.content}
              >
                <TextOverflow maxWidth="620px" value={file.name} />
              </Typography.P2Regular>
            </Col>
            <Col>
              {file.status === "done" && (
                <Button
                  data-testid="btn-copy-url-file"
                  icon={copiedItem === file.uid ? <CheckIcon /> : <CopyIcon />}
                  onClick={() => copyToClipboard(file.uid, file.url)}
                  type="tertiary"
                  iconPosition="iconOnly"
                  size="small"
                />
              )}
              {file.canRemove && (
                <Button
                  data-testid="btn-remove-file"
                  icon={<DeleteIcon />}
                  onClick={() => removeFileByUid(file.uid)}
                  type="tertiary"
                  iconPosition="iconOnly"
                  size="small"
                />
              )}
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  function onClickOnSettingsTab(tabName) {
    setActiveTab(tabName);
  }

  return (
    <div>
      <Tabs
        data-testid="left-panel-tabs"
        tabBarStyle={{ paddingLeft: 16 }}
        onTabClick={onClickOnSettingsTab}
        items={[
          {
            key: TABS.FILES,
            label: t("leftPanel.files"),
            children: renderFileTree(),
          },
        ]}
      />
      {isLoading === true && <Loader overlay />}
      <Modal
        open={imagePreviewURL !== null}
        width="60%"
        onCancel={() => setImagePreviewURL(null)}
        title={imagePreviewURL ? new URL(imagePreviewURL).pathname.split("/").pop() : ""}
        classNames={{ body: style.modal }}
      >
        {imagePreviewURL && <Image src={imagePreviewURL} className={style.imagePreview} />}
      </Modal>
    </div>
  );
}
