import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Button, Modal, ModalClosableFooter, Typography, useAppNotification } from "@ogury/design-system";
import Axios from "axios";
import { Loader } from "components";
import { AccessibilityContext } from "context/AccessibilityContext";
import { useCreativeSettingsContext } from "context/CreativeSettingsContext";
import { useTranslation } from "react-i18next";
import Split from "react-split";
import { useExperience } from "~/context";
import unitService, { syntheticUnitId } from "~/services/UnitService";
import Api from "~/util/Api";
import Communication from "~/util/Communication";
import { buildInternalAssetUrl, FILE_PROTOCOL, NOTIFICATION_PLACEMENT, USE_STUBS } from "~/util/Constant";
import { visitInputs } from "~/util/InputsComputer";
import { isEmpty, isUrl, random } from "~/util/Utils";
import useStores from "../../hooks/useStores";
import Core from "../../services/Core";
import historyStore from "../../services/HistoryStore";
import Debug from "../../util/Debug";
import References from "../../util/References";
import { Header } from "./components";
import CenterPanel from "./components/CenterPanel/CenterPanel";
import LeftPanel from "./components/LeftPanel/LeftPanel";
import RightPanel from "./components/RightPanel/RightPanel";
import style from "./Layout.module.scss";

const useBeforeunload = callback => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    const theCallback = event => {
      savedCallback.current(event);
    };
    window.addEventListener("beforeunload", theCallback);
    return () => {
      theCallback();
      window.removeEventListener("beforeunload", theCallback);
    };
  }, []);
};

function replaceFileProtocolProperties(inputs, experienceAssetsBaseUrl) {
  visitInputs(inputs, (properties, key, value) => {
    if (typeof value === "string" && value.startsWith(FILE_PROTOCOL) === true) {
      Debug.log("Replacing file protocol of input : '" + key + "' with computed value based on assets base URL.");
      properties[key] = experienceAssetsBaseUrl + value.substring(FILE_PROTOCOL.length);
    }
  });
}

const LayoutWrapper = props => {
  const [loaded, setLoaded] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    (async () => {
      await unitService.initialize();
      setLoaded(true);
    })();
  }, []);
  return loaded === false ? <Loader overlay text={t("accessibility.initializing")} /> : <Layout {...props} />;
};

const Layout = ({ history, location, match }) => {
  const notification = useAppNotification();

  const [accessibilityState, accessibilityActions] = useContext(AccessibilityContext);
  const { initializeBuilder, regenerateUiStore } = useStores();
  const { experience, setExperience } = useExperience();
  const { creativeSettings, setCreativeSettings } = useCreativeSettingsContext();

  const [t] = useTranslation();
  // noinspection JSCheckFunctionSignatures
  const [experienceData, setExperienceData] = useState();
  const [fromTemplate, setFromTemplate] = useState(true);
  const [display, setDisplay] = useState({
    sizes: [21, 52, 27],
    studio: false,
  });
  const [afterSaveSeed, setAfterSaveSeed] = useState();
  const [saveSeed, setSaveSeed] = useState(); // TODO refacto this by puttin the experience save method in its own service layer.
  const [parentProxy, setParentProxy] = React.useState();
  const methods = useMemo(() => {
    return {
      shouldClose() {
        return new Promise.resolve(true);
      },
    };
  }, []);

  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [shouldCloseResolve, setShouldCloseResolve] = React.useState();

  // TODO when doing the future refactorization of the Parent Proxy in a hook, remove this to call the hook.
  const [canSaveExperience, setCanSaveExperience] = useState(true);
  const dirty = historyStore.getDirty();

  useEffect(() => {
    methods.shouldClose = () => {
      if (dirty === true && canSaveExperience) {
        setConfirmDialog(true);
        return new Promise(resolve => {
          // Caution: we need to passe a no-argument function, and not directory the function implementation, because React considers the state setter lazy instantiation
          setShouldCloseResolve(() => {
            return shouldClose => {
              resolve(shouldClose);
              setConfirmDialog(false);
              setShouldCloseResolve(undefined);
            };
          });
        });
      } else {
        return Promise.resolve(true);
      }
    };
  }, [dirty]);

  const setDocumentTitle = useCallback(templateName => {
    document.title = "OCS Builder - " + templateName;
  }, []);

  /**
   *  assignUnit is happening after the core inputs initialization and it is based on the "units" property of an experience. If the experience is too old to
   *  have a "units" property, the core has already set the first unit that matches the ratio.
   * @param experience
   *
   * */
  function assignUnit(experience) {
    const experienceUnitTechnicalId = experience.units?.length ? experience.units[0].technicalId : undefined;
    if (experienceUnitTechnicalId) {
      const unitInputInstance = unitService.getCurrentUnitInputInstance();
      unitInputInstance?.setValue(experienceUnitTechnicalId, true, undefined, false);
    }
  }

  async function loadInputsUrl(templateId, url) {
    accessibilityActions.setLoading({
      active: true,
      text: t("accessibility.loadingInputs"),
    });

    let stepMessage = "downloading the inputs";
    try {
      const inputsResponse = await Axios.get(url);
      if (inputsResponse.data == null) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(t("error.invalid.URLDate"));
      }
      const importValues = inputsResponse.data;

      stepMessage = "loading the template definition";
      accessibilityActions.setLoading({
        active: true,
        text: t("accessibility.loadingTemplate"),
      });

      const template = await Api.getTemplate(templateId);
      setDocumentTitle(template.name);
      Core.setCurrentTemplate(template);
      initializeBuilder(false);

      accessibilityActions.setLoading({
        active: true,
        text: t("accessibility.processingData"),
      });

      await Core.generateDeepStoreFromValues(importValues);
      regenerateUiStore();

      notification.success({
        message: t("notification.importDone"),
        placement: NOTIFICATION_PLACEMENT,
      });
    } catch (error) {
      console.warn(error);
      notification.error({
        message: "An error occurred while " + stepMessage,
        description: t("notification.importFailed"),
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  async function loadTemplate(templateId, adUnitTechnicalId) {
    const templateRevision = Api.getTemplateRevisionFromManager();
    accessibilityActions.setLoading({
      active: true,
      text: t("accessibility.loadingTemplate"),
    });

    try {
      const template = await Api.getTemplate(templateId, templateRevision);
      const unitDefinition = template.inputs.find(input => input.id === syntheticUnitId);
      if (adUnitTechnicalId && unitDefinition) {
        unitDefinition.default = adUnitTechnicalId;
      }

      Core.setCurrentTemplate(template);
      initializeBuilder();

      if (adUnitTechnicalId) {
        const availableRatios = unitService.getAvailableRatios(adUnitTechnicalId);
        const recommendedRatio = availableRatios.find(ratio => ratio.isDefault);
        if (
          recommendedRatio &&
          Object.values(template.inputs.find(input => input.id === "ratio").values).find(
            value => value === recommendedRatio.value
          ) // check if API recommend value is part of template
        ) {
          Core.getDeepNodeById("root.ratio", true).value = recommendedRatio.value;
        }
      }

      if (USE_STUBS === true) {
        setExperienceData({ name: "XP" });
      }

      history.replace("/template/" + templateId + location.search);

      notification.success({
        message: t("notification.templateLoaded"),
        placement: NOTIFICATION_PLACEMENT,
      });
    } catch (error) {
      console.warn(error);
      notification.error({
        message: error.message,
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  async function refineExperience(experience) {
    const storageLocationPath = experience?.data.storageLocation.path;
    let experienceAssetsBaseUrl = buildInternalAssetUrl(storageLocationPath);
    Debug.log("Refining experience data with computed base assets URL '" + experienceAssetsBaseUrl + "'");

    if (parentProxy !== undefined) {
      Debug.log("Parent proxy detected");
      const experienceUrls = await parentProxy.call("onExperienceUrls", storageLocationPath);
      if (experienceUrls !== undefined) {
        experienceAssetsBaseUrl = experienceUrls["assetsBaseUrl"];
        Debug.log("A new assets base URL has been injected from proxy : '" + experienceAssetsBaseUrl + "'");
        // We replace the "file://" assets URLs with the experience's assets base URL
        replaceFileProtocolProperties(experience?.data.inputs, experienceAssetsBaseUrl);
      } else {
        experience = await parentProxy.call("onAfterExperienceLoad", experience);
      }
      // END Hooks "onExperienceUrls" / "onAfterExperienceLoad", send experience then wait for return
    } else {
      // STANDALONE ONLY
      replaceFileProtocolProperties(experience?.data.inputs, experienceAssetsBaseUrl);
    }
    experience.assetsBaseUrl = experienceAssetsBaseUrl;
    Debug.success("Experience refined", experience);
    return experience;
  }

  async function loadExperience(parentProxy, experienceId) {
    accessibilityActions.setLoading({
      active: true,
      text: t("accessibility.loadingExperience"),
    });

    try {
      let experience = await Api.getExperience(experienceId);
      setExperienceData({
        name: experience.name,
        state: experience.state,
        builderSettings: experience.data.builderSettings,
      });

      await refineExperience(experience);

      const template = await Api.getTemplate(experience.data.templateId, experience.data.templateVersion, true);
      Core.setCurrentTemplate(template);

      initializeBuilder(false);

      accessibilityActions.setLoading({
        active: true,
        text: t("accessibility.processingData"),
      });

      await Core.generateDeepStoreFromValues(experience.data.inputs);
      assignUnit(experience);
      regenerateUiStore();

      setExperience(experience);
      history.replace("/experience/" + experience.id + location.search);
      notification.success({
        message: "Experience successfully loaded",
        description: "Loading completed",
        placement: NOTIFICATION_PLACEMENT,
      });
    } catch (error) {
      console.warn(error);
      notification.error({
        message: error.message,
        description: "Loading failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  async function loadFromExperienceId(parentProxy, experienceId, adUnitTechnicalId) {
    accessibilityActions.setLoading({
      active: true,
      text: "Duplicating the inputs...",
    });

    try {
      let experience = await Api.getExperience(experienceId);

      const template = await Api.getTemplate(experience.data.templateId, experience.data.templateVersion);
      // use ad unit recommended ratio if available
      if (adUnitTechnicalId) {
        const availableRatios = unitService.getAvailableRatios(adUnitTechnicalId);
        const recommendedRatio = availableRatios.find(ratio => ratio.isDefault);
        if (
          recommendedRatio &&
          Object.values(template.inputs.find(input => input.id === "ratio").values).find(
            value => value === recommendedRatio.value
          )
        ) {
          // check if API recommend value is part of template
          experience.data.inputs.ratio = recommendedRatio.value;
        }
      }

      const experienceNameFromManager = Api.getExperienceNameFromManager();
      setExperienceData({
        name: experienceNameFromManager || experience.name, // use recomended name from manager in case of duplication
        builderSettings: experience.data.builderSettings,
      });

      await refineExperience(experience);

      accessibilityActions.setLoading({
        active: true,
        text: "Loading the template definition…",
      });

      Core.setCurrentTemplate(template);
      initializeBuilder(false);

      accessibilityActions.setLoading({
        active: true,
        text: t("accessibility.processingData"),
      });

      await Core.generateDeepStoreFromValues(experience.data.inputs);
      assignUnit(experience);
      regenerateUiStore();

      setDocumentTitle(template.name);

      notification.success({
        message: "Data successfully imported",
        description: "Import completed",
        placement: NOTIFICATION_PLACEMENT,
      });
    } catch (error) {
      Debug.error("An Error occured while loading the builder from an experience's inputs", error);
      notification.error({
        message: "An error occurred",
        description: "Import failed",
        placement: NOTIFICATION_PLACEMENT,
        duration: 0,
      });
    } finally {
      accessibilityActions.setLoading({ active: false });
    }
  }

  useEffect(() => {
    let theParentProxy;
    const run = async () => {
      if (Api.isInStudioFlavor() === true) {
        setDisplay({
          sizes: [25, 75],
          studio: true,
        });
      }

      const params = match.params;
      // initialize experience data to show header
      setExperienceData({});

      Debug.log("URL parameters :", params);
      if (isEmpty(params) === false) {
        // TODO extract all proxy logic in dedicated service file or hook
        theParentProxy = await Communication.initialize(false, methods);
        setParentProxy(theParentProxy);

        const templateId = params["templateId"];
        const experienceId = params["experienceId"];
        const fromExperienceId = params["fromExperienceId"];
        const adUnitTechnicalId = new URLSearchParams(window.location.search).get("adUnitTechnicalId");

        if (fromExperienceId !== undefined) {
          Debug.log("Loading inputs from experience with ID : " + fromExperienceId);
          await loadFromExperienceId(theParentProxy, fromExperienceId, adUnitTechnicalId);
        } else if (templateId !== undefined) {
          const encodedInputsFileUrl = params["encodedInputsFileUrl"];
          if (encodedInputsFileUrl !== undefined) {
            const url = decodeURIComponent(encodedInputsFileUrl);
            if (isUrl(url) === true) {
              Debug.log("Loading inputs from URL : " + url);
              await loadInputsUrl(templateId, url);
            }
          } else {
            Debug.log("Loading inputs from template ID : " + templateId);
            await loadTemplate(templateId, adUnitTechnicalId);
          }
        } else if (experienceId !== undefined) {
          setFromTemplate(false);
          Debug.log("Loading experience with ID : " + experienceId);
          await loadExperience(theParentProxy, experienceId);
        }

        const _canSaveExperience = await theParentProxy?.call("canSaveExperience");
        if (_canSaveExperience !== undefined && (_canSaveExperience === true || _canSaveExperience === false)) {
          setCanSaveExperience(_canSaveExperience);
        }
      } else if (USE_STUBS === true) {
        history.replace("/template/basic");
        await loadTemplate("basic");
      } else {
        notification.error({
          message: "Failed to load the template: the URL schema is unexpected",
          description: "Loading failed",
          placement: NOTIFICATION_PLACEMENT,
          duration: 0,
        });
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    run();
    return () => {
      if (theParentProxy !== undefined) {
        theParentProxy.destroy();
      }
    };
  }, [methods]);

  useBeforeunload(event => {
    if (parentProxy === undefined && dirty === true) {
      const message = "";
      if (event !== undefined) {
        event.preventDefault();
        event.returnValue = message;
      }
      return message;
    }
  });

  function shouldDisplayShowExperienceButton() {
    // From template means the builder was launched from template (no experience existing at first)
    // display.studio should be false because we want this button to be shown on BO only
    // experience.id
    return fromTemplate === true && display.studio === false && experience.id !== undefined;
  }

  const isLoading = accessibilityState.loading.active === true && accessibilityState.loading.sides.length === 0;

  useEffect(() => {
    const experienceNameFromManager = Api.getExperienceNameFromManager();
    if (experienceNameFromManager !== undefined) {
      setExperienceData({ name: experienceNameFromManager });
    }
  }, []);

  useEffect(() => {
    if (!experience?.data?.builderSettings) {
      return;
    }

    setCreativeSettings({ ...creativeSettings, units: experience.data.builderSettings.units });
  }, [experience]);

  return (
    <section className={style.container}>
      {isLoading === true && <Loader overlay text={accessibilityState.loading.text} />}
      {experienceData && (
        <Header
          experienceData={experienceData}
          isStudio={display.studio}
          loadTemplate={loadTemplate}
          loadExperience={loadExperience}
          parentProxy={parentProxy}
          showExperienceButton={shouldDisplayShowExperienceButton()}
          onAfterSave={() => {
            setAfterSaveSeed(random());
          }}
          saveSeed={saveSeed}
          onExperienceNameChange={name => setExperienceData({ ...experienceData, name })}
        />
      )}
      <div className={style.panelsContainer}>
        <Split
          sizes={display.sizes}
          minSize={[200, 380, 350]}
          className={style.split}
          gutterSize={6}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          cursor="col-resize"
        >
          <div className={style.leftPanel} data-testid="left-panel">
            <LeftPanel />
          </div>
          <div data-testid="center-panel" id={References.CENTER_PANEL_ID} className={style.centerPanel}>
            <CenterPanel />
          </div>
          {display.studio === true ? null : (
            <div className={style.rightPanel} data-testid="preview-panel">
              <RightPanel afterSaveSeed={afterSaveSeed} onReloadAndSave={() => setSaveSeed(random())} />
            </div>
          )}
        </Split>
        <Modal
          open={confirmDialog === true}
          width="40%"
          onCancel={() => shouldCloseResolve(false)}
          title={t("notification.reminder.notSavedTitle")}
          footer={
            <ModalClosableFooter
              buttonLabel={t("components.OkCancelButtons.cancel")}
              actions={<Button onClick={() => shouldCloseResolve(true)}>{t("notification.reminder.discard")}</Button>}
            />
          }
        >
          <Typography.P2Regular>{t("notification.reminder.notSaved")}</Typography.P2Regular>
        </Modal>
      </div>
    </section>
  );
};

LayoutWrapper.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default LayoutWrapper;
